import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  en: {
    dialogs: {
      okButton: 'Ok',
      deleteButton: 'Delete',
      areYouSure: 'Are you sure ?',
      cancelButton: 'Cancel',
      saveButton: 'Save',
      doneButton: 'Done',
      openButton: 'Open',
      closeButton: 'Close',
      sendButton: 'Send',
      createButton: 'Create',
      retryButton: 'Retry',
      deleteColumnTitle: '<p>Delete Column&nbsp;<span class="red--text">{name}</span></p>',
      deleteGridButton: 'Delete Grid',
      deleteGridTitle: '<p>Delete&nbsp;<span class="red--text">{gridTitle}</span></p>',
      deleteFormButton: 'Delete Form',
      deleteFormTitle: '<p>Delete&nbsp;<span class="red--text">{formTitle}</span></p>',
      deleteFormInfo: 'Your share link will no longer work after deleting.',
      deleteVirtualGridButton: 'Delete Grid View',
      deleteVirtualGridTitle: '<p>Delete&nbsp;<span class="red--text">{gridTitle}</span></p>',
      deleteVirtualGridInfo:
        'Your created forms will no longer work after deleting.',
      editEntity: 'Edit Entry',
      editEntityTitle: 'Entry from',
      createEntityTitle: 'Create Entry',
      renameGridButton: 'Rename Grid',
      renameGridTitle: '<p>Rename&nbsp;<span class="primary--text">{gridTitle}</span></p>',
      renameGridText: 'Enter a new name for this Grid',
      deleteSpace: 'Delete Space',
      deleteSpaceTitle: '<p>Delete&nbsp;<span class="red--text">{spaceTitle}</span></p>',
      renameSpaceButton: 'Rename Space',
      renameSpaceTitle: '<p>Rename&nbsp;<span class="primary--text">{spaceTitle}</span></p>',
      renameSpaceText: 'Enter a new name for this Space',
      shareSpaceButton: 'Share Space',
      shareSpaceTitle: '<p>Share&nbsp;<span class="primary--text">{spaceTitle}</span>&nbsp;with others</p>',
      shareSpaceText: 'Send an Invite to your Team via Mail',
      shareSpaceSubmit: 'Invite',
      shareSpaceEmailPlaceHolder: 'Email',
      createSpace: 'Create a Space',
      createSpaceText: 'Let\'s start with a name for your space',
      createGrid: 'Create a Grid',
      createGridText: 'Now choose a name for your first grid',
      createGridMenu: {
        title: 'Create an empty grid',
        importFromData: 'Import grid from data'
      },
      stayOnSpacesButton: 'Later',
      renameVirtualGridButton: 'Rename Grid View',
      renameVirtualGridTitle: '<p>Rename&nbsp;<span class="primary--text">{virtualGridTitle}</span></p>',
      renameVirtualGridText: 'Enter a new name for this Grid View :',
      renameFormButton: 'Rename Form',
      renameFormTitle: '<p>Rename&nbsp;<span class="primary--text">{formViewTitle}</span></p>',
      renameFormText: 'Enter a new name for this Form.',
      setTime: 'Set Time',
      renameAttachmentTitle: '<p>Rename&nbsp;<span class="primary--text">{title}</span></p>',
      renameAttachmentText: 'Enter a new name for this attachment :',
      editFieldKeyTitle: 'Set column\'s key',
      editFieldKeyText: 'This key will be a human readable identifier for this column. Keys can be used to connect a grid to an app.',
      gridKeysDialogButton: 'Manage Field Keys',
      gridKeysTitle: '<p>Manage&nbsp;<span class="primary--text">{gridTitle}</span>&nbsp; fields keys</p>',
      copySpaceTitle: '<p>Copy&nbsp;<span class="primary--text">{spaceName}</span></p>',
      copySpaceText: 'Give a new name for this Space',
      copySpaceButton: 'Copy Space',
      copyOf: 'Copy of {spaceName}',
      copy: 'Copy',
      advancedSettings: 'Advanced Settings',
      setSpaceKeyTitle: '<p>Set&nbsp;<span class="primary--text">{spaceName}</span>\'s Key</p>',
      setSpaceKeyText: 'This key will be a human readable identifier for this Space. Keys can be used to connect a Space to an app.',
      setSpaceKeyButton: 'Set Space\'s Key',
      setGridKeyTitle: '<p>Set&nbsp;<span class="primary--text">{gridTitle}</span>\'s Key</p>',
      setGridKeyText: 'This key will be a human readable identifier for this Grid. Keys can be used to connect a Grid to an app.',
      setGridKeyButton: 'Set Grid\'s Key',
      setSpaceBelongsToTitle: '<p>Set&nbsp;<span class="primary--text">{spaceName}</span>\'s Category</p>',
      setSpaceBelongsToText: 'Set the category of this space',
      setSpaceBelongsToButton: 'Set Space\'s Category',
      extractFieldTitle: 'Move columns to new grid',
      extractFieldText: 'Select the columns you want to move to a new grid. A link will be created in the original grid',
      extractFieldGridNameText: 'Enter a name for the new grid',
      extractFieldButton: 'Move to new grid',
      gridFromCsvTitle: 'Import a CSV',
      gridFromCsvText: 'Create a new Grid from a CSV file',
      gridFromCsvButton: 'Import CSV file',
      gridFromCsvFileInputLabel: 'CSV File',
      gridFromCsvHasHeaderLabel: 'CSV Header ?',
      gridFromCsvHasHeaderHint: 'Does the first line of the CSV file contain the names of the columns ?',
      more: 'More',
      copyData: 'Copy rows',
      copyEntryUri: 'Copy Entry URL',
      signatureTitle: 'Sign Here',
      signatureClearButton: 'Clear',
      signatureSaveButton: 'Save',
      spaceIcon: {
        title: 'Set Space Icon',
        menuItem: 'Set Space Icon'
      },
      camera: {
        permissionText: 'Please allow camera access to start recording.',
        permissionButton: 'Enable Camera'
      },
      apptiveTeams: {
        title: 'Please use Apptive Teams mobile app',
        text: 'This content is only accessible through the Apptive Teams mobile app. Please download the app and log in there to continue.',
        downloadText: 'Download Apptive Teams now to access your content:'
      },
      blockKeysTitle: {
        title: 'Manage Workflow Keys',
        text: 'Keys are used to connect workflow steps to form fields.'
      },
      codeMirror: {
        shortcutHint: {
          prefix: 'Press',
          ctrlKey: 'Ctrl',
          spaceKey: 'Space',
          suffix: 'to insert grid fields'
        }
      }
    },
    forms: {
      builder: {
        title: 'Form Fields',
        remainingFields: 'Remaining form fields',
        addFieldsButton: 'Add Fields',
        createFieldButton: 'Create Field',
        addButton: 'Add',
        label:'Label',
        description:'Description',
        headerImageLabel: 'Form Header image',
        formTitleLabel: 'Form title',
        formTitlePlaceholder: 'Add a title...',
        descriptionLabel: 'Form description',
        descriptionPlaceholder: 'Add a description...',
        formFieldLabelPlaceholder:'Start typing...',
        textsSectionHeader: 'Texts',
        imageSectionHeader: 'Images and Logo',
        colorSectionHeader: 'Colors',
        logoImageLabel: 'Form Logo',
        logoWidthLabel: 'Logo width',
        logoWidthHint: 'Width in percentage of form width',
        moreSettingsSection: 'More Settings',
        primaryColorLabel: 'Primary color',
        buttonTitleLabel: 'Submit button text',
        buttonColorLabel: 'Button color',
        backgroundColorLabel: 'Background Color',
        cardColorLabel: 'Background Color Card',
        headerColorLabel: 'Header Color',
        removeCardShadowLabel: 'Remove card shadow',
        titleSuccesPageHeader: 'Success Page',
        formSuccessTitleLabel: 'Success Title',
        successMessageLabel: 'Success Message',
        formAdditionalAnswerLabel: 'Submit more Button text',
        pagesTabTitle: 'Pages',
        styleTabLabel: 'Layout',
        moreTabLabel: 'More',
        deletePageButtonTitle: 'Remove page',
        addPageButton: 'Add page to form',
        page: 'Page',
        textBlock: 'Text',
        createTextBlockButton: 'Create Text Block',
        newTextBlock: 'Text block',
        textBlockDetails: {
          textInput: 'text',
          styles: {
            HEADER: 'Header',
            PARAGRAPH: 'Paragraph'
          }
        },
        adsSwitchLabel: 'Hide product link',
        afterSubmitAction: {
          actionLabel: 'Action',
          triggerLabel: 'Trigger',
          formRedirectUrlLabel: 'Website',
          formRedirectUrlPlaceholder: 'https://www...',
          redirectButton: 'Button text',
          sendMoreButton: 'Button text',
          redirectButtonPlaceholder: 'Button text',
          additionalAnswer: 'Send additional answer',
          redirect: 'Redirect to website',
          none: 'No action',
          triggerButton: 'Button click',
          triggerAuto: 'Directly after submit',
          triggerDelay: '5 seconds delay',
        },
        saveForLater:{
            featureHeadline: 'Save and prefill form data',
            featureToggleLabel: 'Save and Continue Later',
            featureDescription: 'Respondents can return later, continue where they left off, and easily complete the form without losing their previously entered data.',
            formButtonTitle: 'Save for Later',
            formSuccessButtonTitle: 'Your form has been saved!',
            restoreFormTitle: 'This form was saved and not submitted yet.',
            restoreFormSubtitle: 'You can return to them later to complete or edit them.',
            deleteAllButton: 'Delete all',
            closeButton: 'Done'
        },
        autoSubmit: {
          featureHeadline: 'Auto-Submit on Load',
          featureToggleLabel: 'Auto-Submit',
          featureDescription: 'Enable this feature to automatically submit the form as soon as it is loaded. Start a workflow or capture a QR code scan.',
        },
        hideSubmitButton: {
          featureHeadline: 'Hide Submit Button',
          featureToggleLabel: 'Hide Submit Button',
          featureDescription: 'Enable this feature to hide the submit button on the form. Useful when you use a link to an entry to only show properties of the entry.',
        },
        i18n: {
          featureHeadline: 'Multiple Languages',
          featureToggleLabel: 'Multiple Languages',
          featureDescription: 'Enable this feature to support multiple languages for your form.',
          buttonLabel: 'To Editor',
          dialog: {
            title: 'Form Languages Editor',
            defaultLanguage: 'Default Language',
            backButton: 'done',
            defaultLanguageDescription: 'This is the language corresponding to the text already setup in the Form Builder. For the default language, please edit the text directly in the Form Builder rather than in this dialog.',
            additionalLanguages: 'Additional Languages',
            additionalLanguagesDescription: 'You can add one or multiple additional languages here. You can then edit language specific variants for each piece of text found in the Form.',
            currentLanguage: 'Current Language',
            globalTexts: 'Form global texts',
            globalTextLabels: {
              title: 'Form titel',
              description: 'Form subtitle',
              buttonTitle: 'Submit button',
              successTitle: 'Headline success page',
              successMessage: 'Message success page',
              afterSubmitActionButtonTitle: 'Button success page'
            },
            componentTexts: 'Input labels and descriptions',
            textBlocks: 'Text Blocks'
          }
        },
        fieldStyleSectionHeader: 'Field style',
        fieldStyle_default: 'Floating',
        fieldStyle_outlined: 'Outlined'
      },
      shareForm: {
        title: 'Share your form',
        text:
          'Share your form with others. We have securely published your form.',
          tabs: {
            qrCode: 'QR Code',
            qrCodeText: 'Scan this QR code with a smartphone camera to open the form directly. Alternatively, you can download the QR code as SVG or PNG for further use.',
            link: 'Link',
            openInApp: 'Open in App',
            openInAppExplanation: 'Download the App and Scan the QR Code to add this Form to your mobile App.',
            appStoreBadgeSrc: 'app_store_en.svg',
            playStoreBadgeSrc: 'google-play-badge-en.png',
          },
        buttons: {
          copy: 'Copy',
          open: 'Preview in new tab',
          openPrefillEditor: 'Share Prefilled Form Link',
          addLink: 'Add Link',
          qrCode: 'QR Code',
          setRestrictions: 'Set Restrictions'
        },
        embed: {
          open: 'Embed this view on your website',
          title: 'Embed View',
          subtitle: 'Form',
          autoresizeTitle: 'Autosize height',
          autorisizeDescription: 'Automatically adjust height to fit the form\'s content',
          codePaneHeader: 'Embed code',
          previewMobile: 'Mobile embed preview',
          previewDesktop: 'Desktop embed preview',
        },
        prefillEditor: {
          title: 'Create Link to Prefilled Form',
          subtitle: 'Please enter the data into the corresponding form fields. Copy the generated link and use it to quickly and easily share the form with prefilled data.',
          close: 'Close'
        },
        addLinkDialog: {
          title: 'New Link',
          text: 'Please choose a name for the link',
          formSelectHint: 'Please choose a Form'
        },
        restrictions: {
          title: 'Set Link Restrictions',
          text: 'Chose who can access this link',
          anyone: 'Anyone',
          anyoneDescription: 'Anyone with this link',
          onlyUsers: 'Only Users',
          onlyUsersDescription: 'Only users with this link',
          onlyCollaborators: 'Only Collaborators',
          onlyCollaboratorsDescription: 'Anyone that has been added to this Space as a Collaborator',
        }
      },
      builderFormField: {
        required: 'Required',
        removeButton: 'Remove',
        editButton: 'Edit',
        doneButton: 'Done',
        multiline: 'Multiline',
        back: 'back to form',
        componentType: 'Input Type',
        defaultValueTitle: 'Prefill field',
        componentTypes: {
          selectBox: 'Select Box',
          selectList: 'Select List',
          multiSelectDropdown: 'Select Box',
          multiSelectList: 'Select List',
          videoRecorder: 'Record Video',
          filePicker: 'File picker'
        },
        disabled: 'Read only',
        hidden: 'Hidden',
        fieldSettings: 'Field Settings'
      },
      view: {
        submitButton: 'Submit',
        nextPageButton: 'Next',
        submitMoreButton: 'Submit more answers',
        backButton: 'Back to form',
        successTitle: 'Thank you!',
        successMessage: 'Your answer has reached us.',
        errorTitle: 'Oops - Error',
        previousPageButton: 'back',
        tapToSign: 'Tap here to sign',
        tapToRecord: 'Tap here to record',
        uploading: 'Uploading voice recording'
      },
      formSelectList: {
        otherPlaceholder: 'Or type an other choice in'
      },
      shareButton: 'Share',
      advertisement: 'Create your own free form with ',
      defaultFormTitle: 'Form',
      country: 'country',
      securityHintPassword: 'Never submit passwords through forms.',
      abuse: {        
        actionTitle: 'Report Form',
        title: 'Report a malicious form',
        subtitle: 'Please briefly describe how this form is being misused. Our team will review your report as soon as possible.',
        placeholder: 'Optionally, please briefly describe how this form is being misused.',
        button: 'Submit Report',
        cancel: 'Cancel',
        warning: {
          title: 'Warning: potentially risky form',
          subtitle: 'The user-created form you are attempting to access has been flagged as a potential risk.\n\nIf you wish to proceed and view the form anyways, please remember to never submit your passwords through forms.\n\nPlease contact abuse@apptivegrid.com if you would like to request a review of this form.',
          button: 'Open anyways'
        },
        blocked: {
          title: 'This form is blocked',
          subtitle: 'The user-created form you are attempting to access has been flagged as a risk.\n\nPlease contact abuse@apptivegrid.com if you would like to request a review of this form.'
        }
      },
    },
    webhooks: {
      title: 'Webhooks',
      flowTitle: 'Trigger the flow when',
      description: 'Webhooks are automated messages sent from apps when something happens. They have a message or payload and are sent to a unique URL.',
      nameLabel: 'Name your webhook',
      createHookTitle: 'Webhook',
      defaultWebhookTitle: 'My webhook',
      buttons: {
        create: 'Create webhook',
        save: 'Save',
        delete: 'Delete',
        edit: 'Edit',
      },
      trigger: {
        chooseSpace: 'Choose Space',
        chooseGrid: 'Choose Grid',
        chooseEvent: 'Choose Event',
        entityAdded: 'New Entry added',
        entityUpdated: 'Entry was updated',
        entityAddedEvent: 'New Entry added',
        entityUpdatedEvent: 'Entry was updated',
        chooseForm: 'Choose Form',
        addEntityFormSubmitted: 'New Entry added via Form',
        editEntityFormSubmitted: 'Entry was updated via Form',
        deprecated: 'deprecated'
      },
    },
    links: {
      title: 'Links',
      description: 'Here is a list of all external links in this space',
      types: {
        spaceInvitation: 'Space Invitation',
        externalFlowTrigger: 'Trigger flow',
        addEntity: 'Add Entity',
        editEntity: 'Edit Entity',
        block: 'Page',
        flowInstance: 'Flow execution'
      },
      shareView: {
        restrictions: {
          title: 'Set Access Permissions',
          text: 'Determine who can access this view',
          anyone: 'Everyone',
          anyoneDescription: 'Anyone with the link can access',
          usernamePassword: 'With Credentials',
          usernamePasswordDescription: 'Access with username and password only',
          username: 'Username',
          password: 'Password'
        },
        success: {
          title: 'Success! Permissions Updated',
          message: 'Your link has been successfully updated with the new credentials.',
          textBold: 'Important',
          textBody: 'For security reasons, you won\'t be able to retrieve the username and password again. However, you can update them at any time. Please ensure you keep these credentials secure and share them cautiously.',
          usernameFieldLabel: 'Username',
          passwordFieldLabel: 'Password'
        }
      } 
    },
    validation: {
      notEmpty: 'Must not be empty',
      emailRequired: 'E-Mail must not be empty',
      emailError: 'Valid E-Mail needed',
      passwordRequired: 'Password must not be empty',
      passwordError:
        'Password must contain one small letter, one big letter, one number and minimum one special character: ()[]{}?!$%&/=*+~#,.;:@<>_-',
      nameRequired: 'Name must not be empty',
      maxLength: 'The name can be at most {max} characters long. ',
      nameExistsError: 'The name already exists',
      optionNotEmpty: 'The option must not be empty',
      noDuplicate: 'The option must not be a duplicate',
      required: 'Please complete this required field.',
      integer: 'Only numbers (0-9) are allowed',
      decimal: 'Only floating point numbers are allowed',
      coordinates: 'Must have the latitude,longitude format (e.g. 50.12,6.543)',
      spaceAlreadyShared: 'This space is already shared with this user',
      cannotShareToSelf: 'Spaces cannot be shared with yourself',
      uri: 'Please enter a valid URL',
      invalidPhoneNumber: 'Please enter a valid phone number',
      phoneNumberInternationalIndicator: 'International code is required (e.g. +49)',
      address: 'Please fill required subfields in'
    },
    filterToolBar: {
      filter: 'Filter | {count} Filter | {count} Filters',
      add: 'Add filter',
      allFiltersValidHint: 'All existing filters must be valid',
      addGroup: 'Add filter group',
      maxNestingLevelHint: 'Filter conditions can only be nested 3 levels deep',
    },
    sorting: {
      menuButtonLabel: 'Sort | {count} Sorting | {count} Sortings',
      orderLabel: 'Order',
      ascending: 'ascending',
      descending: 'descending',
      distanceTo: 'distance to',
      addCriteria: 'Add another field to sort'
    },
    grouping: {
      menuButtonLabel: 'Group',
      groupBy: 'Group by',
      count: 'Count ',
      noFieldAvailable: 'No suitable Field for grouping'
    },
    navigationDrawer: {
      createViewHeader: 'Create',
      newVirtualGrid: 'Create View',
      newForm: 'Create Form',
      viewsHeader: 'Views',
      formsHeader: 'Forms'
    },
    spaceMenu: {
      pages: 'Pages',
      flows: 'Flows',
      grids: 'Grids',
      home: 'Show all Spaces'
    },
    kanban: {
      kanbanBoard: 'Kanban Board',
      kanbanBoardShort: 'Kanban',
      onboarding: {
        title: 'Kanban Boards',
        subtitle: 'Overview of your work processes',
        text: 'Display your entries as cards on a Kanban board.'
      },
      settings: {
        menuButton: 'Settings',
      },
      unstacked: 'UNSTACKED',
      unstackedButton: 'Show unstacked cards',
      hideUnstackedButton: 'Hide unstacked cards',
      unnamedEntry: 'Unnamed entry',
      stateSelection: {
        activtor: 'States',
        title: 'Choose a state Field',
        text: 'Each option will represent a column of this Kanban board',
        createTitle: 'Create a state Field',
        noEnum: 'In order to see a Kanban board, your grid must have a column of the single select type. Each option will represent a column of this Kanban board.',
        submitNewEnum: 'Submit'
      }
    },
    gallery: {
      title: 'Gallery',
      settings: {
        menuButton: 'Settings',
        imageMode: 'Image',
        cardSize: 'Card Size',
        fieldIcon: 'Field Icon',
        fieldTitle: 'Field Name',
        firstImage: 'First Image',
        noImage: 'No Image',
        small: 'Small',
        medium: 'Medium',
        large: 'Large',
      },
      onboarding: {
        title: 'Gallery',
        subtitle: 'Overview of your data',
        text: 'Put your entries as cards on a Gallery.'
      },
    },
    list: {
      title: 'List View'
    },
    virtualGrid: {
      addGridButton: 'Add Grid',
      view: 'View',
      defaultName: 'View',
      columnNameLabel: 'Column Name',
      advancedFieldSettings: 'Advanced settings',
      selectButton: 'Select',
      enumOptionsTitle: 'Select Settings',
      enumFilterPlaceholder: 'Find an option',
      enumOtherOption: 'Other input',
      enumOptionsInputPlaceholder: 'New Option',
      enumOptionsExtendedLabel: 'Accept other options',
      crossReferenceOptionsTitle: 'Select a grid view to link entries from',
      currencyOptionsTitle: 'Select a currency',
      resourceOptionsTitle: 'Select one or more resource type (optional)',
      rowFiltered: 'Row Filtered',
      noMatchingEntry: 'No matching entry found.',
      newField: 'New field ',
      sum: 'Sum',
      addEntry: 'Add {fieldName}',
      createEntry: 'Create a new Entry',
      qrCode: {
        buttonText: 'Share',
        dialog: {
          title: '<span>Share&nbsp;<span class="primary--text">{viewName}</span></span>',
          subtitle: 'Share your View with others and work from anywhere.',
          qrcode: 'QR Code',
          openInApp: 'Open in App',
          openInAppExplanation: 'Download the App and Scan the QR Code to add this View to your mobile App.',
          appStoreBadgeSrc: 'app_store_en.svg',
          playStoreBadgeSrc: 'google-play-badge-en.png',
          link: 'Link',
          buttons: {
            copy: 'Copy',
            open: 'Open Link',
            remove: 'Remove'
          }
        }
      },
      shareDialog: {
        notShared: {
          title: 'Create a shareable View link',
          explanation: 'You can send the link to anyone or embed it on a website.',
        },
        shared: {
          title: 'This view is shared via a private link',
          explanation: 'People with the private link can only see data visible in this view.',
          addForm: 'Optionally, you may choose a form to enable the creation of new entries.'
        },
        buttons: {
          share: 'Share'
        }
      },
      filter: {
        conditionalWhere: 'Where',
        contains: 'contains',
        fieldNameLabel: 'Field',
        conditionSelectLabel: 'Condition',
        fieldValueLabel: 'Value',
        hideColumnButton: 'Hide',
        hidenColumnsButton: 'hidden',
        equalCondition: 'equals',
        notEqualCondition: 'does not equal',
        lessCondition: 'is less than',
        greaterCondition: 'is greater than',
        anyOf: 'has any of',
        noneOf: 'has none of',
        allOf: 'has all of',
        isEmpty: 'is empty',
        isNotEmpty: 'is not empty',
        doesNotContain: 'does not contain',
        isActor: 'is',
        isNotActor: 'is not',
        beforeCondition: 'is before',
        afterCondition: 'is after',
        noAvailableFilter: 'No available filter',
        explanation: 'In this view, show entries',
        composite: {
          label: 'combination',
          and: 'and',
          or: 'or',
          orExplanation: 'Any of the following are true...',
          andExplanation: 'All of the following are true...'
        }
      },
      rowMoreMenu: {
        share: 'Share Entry'
      },
      moreMenu: {
        csvExportButton: 'CSV Export',
        cloneVirtualGrid: 'Duplicate'
      },
      shareEntity: {
        title: 'Share link to edit entry',
        subtitle: 'Share your entry with anyone.',
        textShared: 'We have securely published a form to edit this entry and it is available at the following web address.',
        textNoForm: 'First create a form to share this entry with anyone.',
        selectForm: 'Select a form'
      },
      batchDelete: {
        openDialogButton: 'Delete {numberOfEntities}',
        cancel: 'Cancel',
        confirmDelete: 'Delete',
        titel: 'Delete {numberOfEntities} Entries?',
      }
    },
    user: {
      userDevSwitch: 'Try out our latest features by toggling to beta.'
    },
    columnTypes: {
      text: 'Text',
      richText: 'Rich Text',
      number: 'Number',
      decimal: 'Decimal',
      dateAndTime: 'Date and time',
      date: 'Date',
      checkmark: 'Checkmark',
      singleSelect: 'Single Select',
      enumCollection: 'Multiple Select',
      crossReference: 'Link to a row of an other grid',
      crossReferences: 'Links to multiple rows of an other grid',
      attachments: 'Attachments',
      geolocation: 'Geolocation',
      address: 'Address',
      createdBy: 'Created by',
      createdAt: 'Created at',
      user: 'User',
      currency: 'Currency',
      uri: 'URL',
      email: 'E-Mail',
      phoneNumber: 'Phone Number',
      signature: 'Signature',
      lookup: 'Lookup',
      sumup: 'Sum-Up',
      formula: 'Formula',
      resource: 'Internal Link'
    },
    views: {
      previewButton: 'Preview',
      spacesTitle: 'Spaces',
      logoutButton: 'Logout',
      reloadButton: 'Reload view',
      updateInfo: 'Your Grid was updated'
    },
    spaces: {
      greetings: {
        early_morning: 'Good morning {name} ☕',      // for early morning (until 10am)
        late_morning: 'Hi {name} 👋',                 // for late morning (10am-12pm)
        afternoon: 'Welcome {name} 👋',               // for afternoon
        evening: 'Good evening {name} 👋'             // for evening
      },
      createNewSpaceButton: 'Create new Space',
      emptyStateHeadline: 'Create your first Space',
      allSpaces: 'All Spaces',
      sharedWithMe: 'Shared with me',
      groupByBelongsTo: 'Group by Category',
      searchPlaceholder: 'Search space...',
      noGroup: 'No Category'
    },
    csvImport: {
      buttons: {
        openComponent: 'Import from CSV',
        loading: 'Loading...'
      },
      componentTitle: 'CSV Import',
      steps: {
        loadFile: {
          title: 'Load CSV file',
          fileFormatConstrainsInfo: 'The CSV file must be created in UTF-8 format.',
          hasHeaderRow: 'Header?',
          fileFormatInvalid: 'File type is invalid',
          loadFileButton: 'Load File',
          loadCSVButton: 'Load CSV',
          mappingTableHeaderGrid: 'Grid',
          mappingTableHeaderCSV: 'CSV',
          ignoreOptionText: 'Ignore'
        },
        preview:{
          title: 'Preview',
          importButton: 'Import data'
        },
        import: {
          title: 'Importing',
          progressDescription: 'Importing {numberOfEntities} entries.'
        },
        done: {
          title: 'Done',
          result: '{numberOfImportedEntities} entries imported.'
        }
      }
    },
    settings: {
      spaceSettingsTitle: 'Space Settings',
      apiCredentialsNavigation: 'API Credentials',
      accountSettingsButton: 'Profile & Settings',
      accountSettingTitle: 'Profile & Settings',
      profileNavigationTitle: 'Profile',
      webhooks: 'Webhooks',
      payment: 'Payment',
      user: 'User',
      links: 'Links',
      generalSpaceSettings: {
        title: 'General',
        spacePlan: 'This space is in the plan',
        spacePlanDescription: 'The owner can customize the plan.',
        changePlanButton: 'Adjust Plan and Subscription',
        info: 'Info',
        spaceId: 'Space Identifier',
        createdAt: 'Created at',
        availableSeats: 'Available seats for users with editing rights: ',
        availableSeatsDescription: 'The owner can acquire additional seats for users with editing rights. Reading rights are free of charge.',
        changeAvailableSeatsButton: 'Adjust seats',
        trialPeriod: 'Remaining trial days: ',
      }
    },
    createApiKey: {
      title: 'API CREDENTIALS',
      explanation: 'This is your personal API credentials. They are required in order to use the API. Your personal API credentials grant access to all the data in your Spaces. Only share this credentials with third - party services and applications that you trust.',
      dialogTitle: 'Create API Credentials',
      dialogText: 'Choose a name for your credentials',
      dialogOkButtonLabel: 'Create',
      buttonLabel: 'Create api credentials',
      roleSelectLabel: 'These credentials will grant:',
      adminDescription: 'Full access to your spaces',
      readerDescription: 'Read only access to your spaces',
      adminLabel: 'Admin',
      readerLabel: 'Read only',
    },
    newApiCredentials: {
      title: 'API credentials',
      successMessage: 'Your credentials are available',
      textBold: 'Save your authorisation key and password now',
      textBody: 'This is the only time the credentials can be viewed or downloaded. They cannot be recovered later. However, you can delete your API credentials and create new ones at any time.',
      keyFieldLabel: 'Authorisation key',
      secretFieldLabel: 'Password'
    },
    errorMessages: {
      cannotRemoveReferencedObject: 'Cannot delete. The entry is referenced in another place.',
      fieldReferencedByFilter: 'Change not possible. There is a filter associated with this field.',
      userWithEmailNotFound: 'user with email {email} does not exist',
      nestedReferencesDetected: 'Links to grids that have already been linked elsewhere are not yet possible.',
      spaceAccessDenied: 'You don\'t have access to that space anymore.',
      spaceNotFound: 'This space has been deleted',
      unrecognisedDate: 'Unrecognised date format : {date}',
      unaccessibleView: 'This View is no longer accessible.',
      notAllowed: 'Not Allowed',
      formNotAccessable: 'Access to this form is restricted',
      noNextFlowFormPage: 'No custom UI is assigned for this step. If you need a UI, you can add it in the Flow Editor.'
    },
    calendar: {
      title: 'Calendar',
      newEvent: 'New Event',
      types: {
        day: 'Day',
        week: 'Week',
        month: 'Month',
      },
      today: 'Today',
      onboarding: {
        title: 'Calendar',
        subtitle: 'Manage dates clearly',
        text: 'To see a calendar view, your grid must have a column of type Date.'
      },
      stateSelection: {
        activtor: 'Calendar Settings',
        title: 'Set start/end fields for Events',
        text: 'Chose a date or date and time field as start and optionally end for the events of the calendar.',
        createTitle: 'Create a date Field',
        noDate: 'In order to see a Calendar, your grid must have at least one date or date and time column.',
        submit: 'Submit',
        start: 'Start Field',
        end: 'End Field (optional)',
      }
    },
    avatarEditor: {
      errors: {
        fileWrongFormat: 'Please choose an jpg image file',
        fileSizeToBig: 'Your file is too big! Please select an image under 1MB'
      }
    },
    attachments: {
      pondLabel: 'Drop files here... or <u>click to Browse</u>',
      downloadButtonLabel: 'Download',
      renameButtonLabel: 'Rename'
    },
    map: {
      title: 'Map',
      searchHere: 'Search Here',
      onboarding: {
        title: 'Map',
        subtitle: 'Visualise Geolocations',
        text: 'Display your entries as markers on a Map.'
      },
      stateSelection: {
        activtor: 'States',
        title: 'Choose a state Field',
        text: 'The geolocation will determine the position of the markers on the map.',
        createTitle: 'Create a state Field',
        noGeolocation: 'In order to see a Map, your grid must have a column of the geolocation type. it will determine the position of the markers on the map.',
        submitNewEnum: 'Submit'
      }
    },
    roles: {
      selectLabel: 'The user will be able to:',
      owner: 'Owner',
      admin: 'Manage',
      adminLabel: 'Manage',
      adminDescription: 'Fully edit and configure that space',
      reader: 'Read only',
      readerLabel: 'Read',
      readerDescription: 'Not edit or configure that space',
      writer: 'Edit entries',
      writerLabel: 'Edit',
      writerDescription: 'Edit Entries but not edit that space'
    },
    createdBy: {
      formLink: 'Form Link',
      accessKey: 'API Credentials'
    },
    spaceShareList: {
      header: 'Shared with'
    },
    spaceInvitesList: {
      header: 'Pending Invitations'
    },
    expressions: {
      exactDate: 'exact date',
      exactValue: 'exact value',
      today: 'today',
      tomorrow: 'tomorrow',
      yesterday: 'yesterday',
      daysAgo: 'days ago',
      daysFromToday: 'days from today',
      loggedInUser: 'logged in user',
      ownerEmail: 'Owner Email',
      ownerFirstName: 'Owner First Name',
      ownerLastName: 'Owner Last name',
      empty: 'Empty Expression',
      unknown: 'Unknown Expression'
    },
    profile: {
      title: 'Profile',
      profileImage: 'Profile image',
      firstName: 'First name',
      lastName : 'Last name',
      email: 'E-Mail',
      delete: {
        buttonLabel: 'Delete Account',
        cancelButtonLabel: 'Cancel',
        description: 'Your account and all data will be irrevocably deleted. Type DELETE in the text box to continue.'
      }
    },
    payment: {
      title: 'Billing and Plans',
      billing: {
        explanation: 'Manage subscriptions, load invoices and edit payment or billing data.',
        button: 'Invoices and subscription',
        currentPlan: 'You are on Plan'
      },
    },
    templates: {
      title: 'Or start with a Template'
    },
    flow: {
      flowlist: {
        buttons: {
          addFlow: 'Add Flow',
          createNewFlowButton: 'Create Flow',
        },
        labels: {
          emptyStateHeadline: 'Start to automate your work.',
        },
        allFlows: 'All Flows'
      },
      flowView: {
        editorTab: 'Editor',
        historyTab: 'History'
      },
      instanceList: {
        unknownDate: 'Unknown Date',
        labels: {
          started: 'Started',
          steps: 'Steps',
          state: 'State',
          open: 'Open'
        },
        states: {
          done: 'Done',
          error: 'Error'
        }
      },
      editor: {
        testRunSnack: 'The flow is ready to be tested',
        errors: {
          testRunFailed: 'Failed to test run flow',
          noPreviousRunFound:'No previous run found. Please run the flow first.',
        },
        buttons: {
          runflow: 'Run flow',
          testflow: 'Test flow',
          waitforNewRunTitle: 'Wait for next run',
          waitforNewRunDescription: 'Wait for the flow to be executed again.',
          reRunFlowTitle: 'Restart',
          reRunFlowDescription: 'With data from last run',
          testRunFlowTitle: 'Test flow',
          testRunFlowDescription: 'Uses last row of the grid',
          testRunWithPayloadTitle: 'Run test with payload',
          testRunWithPayloadDescription: 'Define an example payload',
          openPageAndWaitUntilNextRun: 'With data from flow Form',
          openPageAndWaitUntilNextRunDescription: 'Open the form and test with submitted data',
        },
        instanceInfo: {
          autoLoadedTitle: 'Viewing previous run data',
          testInstanceTitle: 'Test instance',
          description: 'This is data from a previous flow run. It was automatically loaded to help you understand the flow\'s behavior.',
          runAt: 'Run at',
          loadedAt: 'Loaded at',
          status: 'Status',
          steps: 'Steps',
          selectOther: 'Select another instance',
          clear: 'Clear'
        },
        instanceSelection: {
          title: 'Select Flow Instance',
          loading: 'Loading flow instances...',
          noInstances: 'No flow instances found',
          instance: 'Instance',
          current: 'Current',
          created: 'Created',
          close: 'Close'
        },
        instanceStatus: {
          previousRun: 'Previous run',
          selectedInstance: 'Selected instance',
          testInstance: 'Test instance',
          completed: 'Completed',
          failed: 'Failed',
          inProgress: 'In progress',
          created: 'Created'
        }
      },
      nodeMenu: {
        rename: 'Rename',
        delete: 'Delete',
        configure: 'Configure',
        addConnection: 'Add Connection'
      },
      nodeIndicator: {
        showDetails: 'Show Details',
        showErrorDetails: 'Show Error Details'
      },
      nodes: {
        start: {
          name: 'Trigger',
          description: 'An Event occured'
        },
        entityAdded: {
          name: 'New Entry',
          description: 'A new Entry was added'
        },
        entityUpdated: {
          name: 'Entry Changed',
          description: 'An existing Entry was changed'
        },
        addEntityFormSubmitted: {
          name: 'New Entry via Form',
          description: 'A Form submission has added a new Entry',
        },
        editEntityFormSubmitted: {
          name: 'Entry Changed via Form',
          description: 'A Form submission has edited an Entry',
        },
        end: {
          name: 'End',
          description: 'Something that ends the Flow'
        },
        exclusiveGateway: {
          name: 'Router',
          description: 'Do different ways'
        },
        sendEmail: {
          name: 'E-Mail',
          description: 'Send a Mail'
        },
        sendHttpRequest: {
          name: 'HTTP Request',
          description: 'Send an arbitrary HTTP request'
        },
        qrCode: {
          name: 'QR Code',
          description: 'Generates a QR code'
        },
        requestTrigger: {
          name: 'Webhook',
          description: 'Triggered by an external request',
          configuration: {
            title: 'Link to Start the Flow',
            description: 'To start the flow, send a POST request to the following URL:',
            postRequestTitle: 'Send POST Request',
            postRequest: 'Send data in JSON format via POST request to the above link to start the flow.',
            webhookTitle: 'Use as Webhook',
            webhook: 'Register the URL as a webhook in other services. This will automatically start the workflow when the respective service triggers the webhook.',
            testWebhookTitle: 'Test the Webhook',
            testWebhookDescription: 'You can test the webhook by sending a sample JSON payload using the form below:',
            jsonPayloadTitle: 'JSON Payload',
            sendTestRequestButton: 'Test now',
            redirectDescription: 'Use the link below to open the first page of our flow. Once this page is submitted, the flow will start.',
            redirectTips: 'You can also share this link, for example:\n\n- As a QR code for easy access\n- Link it on your website or intranet\n\nShare the link with others to start the workflow quickly and efficiently.'  
          }
        },
        updateEntity: {
          name: 'Update Entry',
          description: 'Update an Entry with new values'
        },
        editLink: {
          name: 'Prefilled form link',
          description: 'Updates an entry via a prefilled form',
        },
        addEntity: {
          name: 'Add Entry',
          description: 'Creates an new Entry'
        },
        flowForm: {
          name: 'Display Page',
          description: 'Displays a page for user interaction'
        },
        parallel: {
          name: 'Parallel',
          description: 'Executes the following steps for each item in a list individually.'
        },
        pdf2text: {
          name: 'PDF to Text',
          description: 'Convert PDF to Text'
        },
        speechToText: {
          name: 'Speech To Text',
          description: 'Convert audio to text'
        },
        listViewEntities: {
          name: 'List View Entries',
          description: 'List all entries related to a view'
        },
        flowInstanceLink: {
          name: 'Create Link to Page',
          description: 'Generates a link to a page within the flow'
        },
        flowFormPageTrigger: {
          name: 'Show Page',
          description: 'Starts a flow with a page'
        }
      },
      nodeProperties: {
        pdf2text: {
          pdf_url: 'PDF File URL',
          returnArray: 'Extract text per page'
        },
        sendEmail: {
          from: 'From name',
          html: 'Use HTML in content',
          replyTo: 'Reply To',
          recipients: 'Recipients',
          subject: 'Subject',
          body: 'Content',
        },
        sendHttpRequest: {
          url: 'URL',
          requestContent: 'Request Content',
          method: 'Method',
          contentType: 'Content Type',
          query: 'Query',
          headers: 'Headers',
          username: 'Username',
          password: 'Password',
        },
        openAICompletion: {
          user: 'Prompt',
          system: 'Additional context'
        },
        gridPicker: 'Please select a Grid',
        formPicker: 'Please select a Form',
        flowFormPicker: 'Please select a Page',
        viewPicker: 'Please select a View',
        flowForm: {
          form: 'Button to submit form',
          buttonLabel: 'Button Text'
        }
      },
      variablePicker: {
        title: 'Values from previous steps',
        headerEmpty: 'First, you need to test run the flow',
        descriptionEmpty: 'Test run gives you access to flows data to use in fields.',
        flowVariables: 'Flow Variables',
        search: 'Search',
        expressionsTitle: 'Expressions',
      },
      condition: {
        title: 'Only continue if...',
        leftOperandLabel: 'Choose value...',
        operatorLabel: 'Choose condition...',
        rightOperandLabel: 'Enter text ...',
        saveButton: 'Save',
        clearButton: 'Clear',
        expression: 'Show filter expression',
        editConditionButton: 'Edit filter',
        deleteConditionButton: 'Delete connection',
        editConditionLabelButton: 'Edit connection',
        editConditionLabel: 'Connection',
        unrecognizedExpression: 'Unrecognized expression'
      },
      templateKeyValues: {
        name: 'Name',
        value: 'Value'
      },
      templateFilePicker: {
        fileName: 'File Name',
        fileType: 'File Type',
        fileData: 'Contents'
      },
      instanceDetail: {
        output: 'Output'
      },
      nodePicker: {
        featureRequest: 'Don\'t see an action you\'d like?',
        featureRequestAction: 'Make your Request',
        triggerHeader: 'Triggers',
        actionsHeader: 'Actions',
        helperHeader: 'Helpers',
        whenShouldFlowStart: 'When should the flow start?',
        hasStartNode: 'The flow already has a start node',
        actionsDescription: 'Choose an action to add to your flow'
      },
      flowFormPicker: {
        addPage: 'Add Page',
        editPage: 'Edit Page'
      }
    },
    operators: {
      isEqualTo: 'is equal to',
      isNotEqualTo: 'is not equal to',
      greaterThan: 'is greather than',
      smallerThan: 'is smaller than',
      smallerThanOrEqual: 'is smaller than or equal to',
      greaterThanOrEqual: 'is greater than or equal to',
      endsWith: 'ends with',
      contains: 'contains',
      beginsWith: 'begins with',
      after: 'is after',
      before: 'is before',
      isEmpty: 'is empty',
      isNotEmpty: 'is not empty',
      isTrue: 'is true',
      isFalse: 'is false',
      logicalAnd: 'and',
      logicalOr: 'or'
    },
    paywall: {
      upgradeNow: 'Upgrade Now',
      upgradeNowLong: 'Upgrade to unlock this feature',
      upgradeTeaserTitle: 'Upgrade to unlock more features',
      upgradeTeaserSubtitle: 'You\'re currently subscribed to our {planName} plan. If you need advanced features, upgrade now to Plus or Pro plan.',
      upgradeTeaserPlansButton: 'Compare all plans',
      upgradeTeaserChatButton: 'Chat with us',
      upgradeSeats: 'Acquire seats',
      myPlan: 'Your plan',
      trial: {
        title: 'Trial Period',
        daysRemaining: '{days} days remaining in trial'
      },
      seatsConsumed: {
        title: 'Exceeding the licensed seats in your plan',
        moreUsersInvited: 'It seems you have invited more users than you have licensed seats for.',
        needAdditionalPlaces: 'You need {numberOfNeededSeats} more seats.',
        needAdditionalPlace: 'You need one more seat.',
        changeUserPermissions: 'Change user permissions to "Read-only" or book additional seats.',
      },
      proNodes: {
        shortHint: 'Pro nodes',
        longHint: 'Upgrade to Pro to use advanced nodes like OpenAI, PDF to Text, and more'
      },
      webhooksPerSpace: {
        shortHint: 'Add more Webhooks',
        longHint: 'Upgrade to add more Webhooks'
      },
      flows: {
        shortHint: 'Add more flows',
        longHint: 'Upgrade to add more flows and get more runs'
      },
      saveFormProgress: {
        shortHint: 'Save and continue later',
        longHint: ''
      },
      formSuccessRedirect: {
        shortHint: 'Redirect to websites',
        longHint: ''
      },
      formHideAd: {
        shortHint: 'Hide product link',
        longHint: ''
      },
      writersPerSpace: {
        shortHint: 'Add more writers and admins',
        longHint: ''
      },
      entitiesPerGrid: {
        shortHint: 'Add more entities',
        longHint: 'Entity per grid limit reached. Upgrade to add more.'
      },
      formReadOnlyFields: {
        shortHint: 'Read only field in forms',
      },
      formDefaultValues: {
        shortHint: 'Prefill form fields',
      },
      formHiddenFields: {
        shortHint: 'Hide prefilled form fields',
      },
      autoSubmitForms: {
        shortHint: 'Auto submit form',
      },
      downloadQRCodeSVG: {
        shortHint: 'Create SVG file'
      },
      upgardeBanner: {
        longHint: 'Want to Get the Most Out of Our Platform?'
      },
      formsPerSpace: {
        shortHint: 'Add more Forms',
      },
      spaceColors: {
        shortHint: 'Personalised space colours',
      },
      logoOnForms: {
        shortHint: 'Add Your Logo',
      },
      addExternalLinks: {
        shortHint: 'Add and manage multiple external Links',
      },
      formI18n: {
        shortHint: 'Add and manage multiple languages for your Form',
      },
      signature: {
        title: 'Signature',
        subtitle: 'Sign your name'
      },
    },
    spaceInvitation: {
      inviteText: 'You have been invited to a Space!',
      inviteAcceptButtonText: 'Accept',
      errorWrongEmail: 'The invitation can only be accepted with the associated email address.',
      error: 'The invitation could not be accepted.'
    },
    helpMenu: {
      title: 'Help & Support',
      helpCenter: 'Help Center',
      chatWithUs: 'Chat with us',
      feedback: 'Send us Feedback'
    },
    iconPicker: {
      removeButton: 'Reset',
      searchIcons: '\u{1F50E} Search Icons',
      uploadFileIcon: 'Upload icon file',
      changeFileIcon: 'Change icon file',
      removeFileIcon: 'Remove icon file',
      uploadFileIconPrompt: 'Drop image here... or <u>click to Browse</u>'
    },
    entityUpdatedCondition: {
      hint: 'When',
      fieldSelectPlaceholder: 'any field',
      fieldSelectLabel: 'has changed',
      parameterPlaceholder: 'Any value',
      anyValue: 'Any value',
      noValue: 'No value',
      from: 'From',
      to: 'To'
    },
    entityPicker: {
      addButton: 'Select {fieldName}',
      filterPlaceholder: 'Search ...',
      createEntryButton: 'New {fieldName}',
      linkedEntries: 'Linked Entries',
      searchResults: 'Search Results',
      loadMore: 'Load More... ({items} / {totalItems})',
      empty: 'No entry',
      error: 'This entry is not available for you'
    },
    editableTexPlaceholder: 'Start typing here ...',
    errorReport: {
      title: 'Error Occurred',
      subtitle: 'We are sorry, but an error has occurred. Please excuse the inconvenience. Below you will find the details of the error.',
      addReportButton: 'Add Note',
      placeholder: '(optional) Please describe when the error occurred.'
    },
    entityCount: '{count} entries',
    lookupOptions: {
      referenceLabel: 'Select a reference column',
      lookupLabel: 'Select a column from {gridName}'
    },
    sumupOptions: {
      referenceLabel: 'Select a multi-reference column',
      sumupLabel: 'Select a number column from {gridName}'
    },
    formulaOptions: {
      expressionLabel: 'Formula',
      valueTypeModel: 'Formula result as',
      showAsToken: 'Show formula as token',
      showAsText: 'Show formula as text',
      noSupportedField: 'No field of a supported type is available. Supported types : {types}'
    },
    onboarding: {
      purposeTitle: 'In which area do you want to use ApptiveGrid?',
      private: 'Private',
      work: 'Work',
      other: 'Other',
      welcome: 'Welcome'
    },
    addressPicker: {
      country: 'Country',
      line1: 'Address Line 1',
      line2: 'Address Line 2',
      city: 'City',
      postCode: 'Post Code',
      state: 'State'
    },
    login: {
      title: 'Welcome!',
      subtitle: 'Let us unlock this view. Enter the credentials below.',
      username: 'Username',
      password: 'Password',
      button: 'Login',
      error: 'Please check username and password',
      rule: {
        username: 'Username is required',
        password: 'Password is required'
      }
    },
    blockTypes: {
      page: {
        title: 'Page',
        subtitle: 'Embed a sub-page inside this Page'
      },
      paragraph: {
        title: 'Paragraph',
        subtitle: 'Write normal text',
      },
      header1: {
        title: 'Heading 1',
        subtitle: 'Large heading',
      },
      header2: {
        title: 'Heading 2',
        subtitle: 'Medium heading',
      },
      header3: {
        title: 'Heading 3',
        subtitle: 'Small heading',
      },
      text: {
        title: 'Single-line Text Field',
        subtitle: 'For short inputs like Name, Company or Subject.'
      },
      textArea: {
        title: 'Multi-line Text Field',
        subtitle: 'For longer inputs like Comments or Descriptions.'
      },
      grid: 'Grid',
      dateTime: 'Date and Time',
      dictionary: 'Dictionary',
      attachmentCollection: {
        title: 'File Upload',
        subtitle: 'Upload one or more files'
      },
      uri: {
        title: 'URL',
        subtitle: 'Enter Website Link'
      },
      decimal: {
        title: 'Decimal',
        subtitle: 'Enter a number with decimals'
      },
      mimeEntityCollection: 'Mimeentitycollection',
      entity: {
        title: 'Entry',
        subtitle: 'Select an entry from a Grid'
      },
      integer: {
        title: 'Number',
        subtitle: 'Enter a number'
      },
      mimeEntity: 'Mimeentity',
      enumCollection: {
        title: 'Multiple Select',
        subtitle: 'Select multiple values from a list'
      },
      user: 'User',
      location: 'Location',
      boolean: 'Checkmark',
      currency: 'Currency',
      date: 'Date',
      multiText: 'Multitext',
      address: 'Address',
      enum: {
        title: 'Select',
        subtitle: 'Select a value from a list'
      },
      entityCollection: {
        title: 'Entries',
        subtitle: 'Select multiple entries from a Grid'
      },
      signature: {
        title: 'Signature',
        subtitle: 'Sign your name'
      },
      email: {
        title: 'Email',
        subtitle: 'Enter an Email Address'
      },
      phoneNumber: 'Phone Number',
      attachment: 'Attachment',
      resource: 'Internal Link',
      block: 'Block',
      resourceBlock: {
        title: 'Grid View',
        subtitle: 'Embed a View e.g. Grid, Kanban, Calendar, Map or Gallery',
      },
      externalHookButtonBlock: {
        title: 'Button',
        subtitle: 'Button to start a form or a flow',
      },
      embed: {
        title: 'Embed',
        subtitle: 'Embed any external content, e.g., google docs, websites or videos.',
      },
      video: {
        title: 'Video',
        subtitle: 'Add a mandatory video to your form',
      },
      image: {
        title: 'Image',
        subtitle: 'Add an image with custom dimensions',
      },
      voiceRecorder: {
        title: 'Voice Recorder',
        subtitle: 'Record a voice message',
      },
      gridCountCardBlock: {
        title: 'Counter',
        subtitle: 'Count the number of entries in a view',
      },
      horizontalLayout: {
        title: 'Horizontal Layout',
        subtitle: 'Arrange blocks in a horizontal layout',
      },
      html: {
        title: 'HTML',
        subtitle: 'Add custom HTML content with template variables',
      },
      entityList: {
        title: 'Entry List',
        subtitle: 'Create a list of blocks based on grid entries',
      },
    },
    blocks: {
      emptyParagraph: 'Empty Paragraph...',
      emptyHeader: 'Empty Header...',
      headerLevel: 'Heading size',
      textAlignment: 'Text alignment',
      textAlignments: {
        left: 'Left align',
        center: 'Center align',
        right: 'Right align',
        justify: 'Justify'
      },
      backgroundColor: 'Background Color',
      textColor: 'Text Color',
      emptyPageTitle: 'unnamed',
      pages: 'Pages',
      basicHeader: 'Basic Blocks',
      formFieldHeader: 'Form Field Blocks',
      specialHeader: 'Special Blocks',
      pickerSearchPlaceholder: 'Type to filter...',
      pickerPlaceholder: 'Start typing or hit space to open the block picker...',
      setHeaderImage: 'Add cover image',
      headerImage: 'Insert image url',
      setIcon: 'Set Icon and Color',
      deletePage: 'Delete Page',
      deleteBlock: 'Delete Block',
      submitPage: 'Next',
      blockSettings: 'Block Settings',
      renamePage: 'Rename Page',
      renamePageTitle: '<p>Rename&nbsp;<span class="primary--text">{name}</span></p>',
      headers: {
        h1: 'Large heading',
        h2: 'Medium heading',
        h3: 'Small heading'
      },
      emptyStateBlocksHeader: 'Welcome to your "Pages" section',
      emptyStateBlocksDescription: 'Here, you can store all your information, notes, and documentation. Click "Create Page" to capture everything that is important to you!',
      emptyStateBlocksAddPage: 'Create Page',
      addPage: 'Create Page',
      resourceBlock: {
        menuTitle: 'Select a Grid View',
        inputLabel: 'Grid View',
        menuButton: 'Select a Grid View',
        switchToFullsize: 'Show full width'
      },
      externalHookButtonBlock: {
        menuTitle: 'Select a Link',
        inputLabel: 'Link',
        menuButton: 'Select a Link',
        backgroundColor: 'Background Color',
        textColor: 'Text Color'
      },
      embed: {
        menuTitle: 'Set the link to be embedded',
        uriInputLabel: 'Embed link'
      },
      video: {
        menuTitle: 'Set the video Url',
        inputLabel: 'Video Url'
      },
      image: {
        menuTitle: 'Image Settings',
        urlLabel: 'Image URL',
        widthLabel: 'Width (px/%)',
        heightLabel: 'Height (px/%)'
      },
      inputBlock: {
        blockSettings: 'Block Settings',
        required: 'Required',
        requiredFieldError: 'This field is required',
        needToViewToEnd: 'Please view the video to the end.',
        delete: 'Delete',
        requiredVideo: 'Require to view video',
        readonly: 'Readonly',
      },
      setKeys: 'Set Keys',
      shareDialog: {
        title: 'Publish this page',
        subtitle: 'Publish this page with others',
        buttons: {
          publish: 'Publish',
          remove: 'Remove',
          open: 'Open',
          qrCode: 'QR Code',
          copy: 'Copy',
          setRestrictions: 'Set Restrictions',
        }
      },
      horizontalLayout: {
        inputLabel: 'Arrange',
        menuButton: 'Arrange',
        layoutDirection: 'Arrange',
        horizontal: 'Horizontal',
        vertical: 'Vertical',
        mainAxisAlignment: 'Horizontal',
        counterAxisAlignment: 'Vertical',
        alignStart: 'Start',
        alignCenter: 'Center',
        alignEnd: 'End',
        alignMin: 'Top',
        alignMax: 'Bottom',
        spaceBetween: 'Space Between',
        stretch: 'Stretch',
      },
      switchType: 'Switch Type',
      htmlBlock: {
        editHtml: 'Edit HTML',
        content: 'HTML Code',
        preview: 'Live Preview',
        templateHelp: 'Use {{ }} for template variables. Example: {{variableName}}',
        description: 'Describe changes needed (e.g., "Add a footer" or "Make the card responsive")',
      },
      entityList: {
        selectGrid: 'Choose Grid',
        selectGridDescription: 'Choose a grid to automatically create a list of entries.',
        selectItemBlock: 'Edit list item block',
        gridEntryPlaceholder: 'Grid entry placeholder',
      },
      setViewType: 'Set View Type',
      viewTypes: {
        default: 'Centered Page',
        full: 'Full Width Page',
      },
    },
    expressionEditor: {
      menuTitle: 'Edit an Expression',
      sampleResult: 'Sample Result',
      typeDisplay: 'Type: {type}',
      expressionLabel: 'expression'
    },
    resourceTypes: {
      grid: 'Grids and Views',
      persistentGrid: 'Persistent Grids',
      view: 'Views',
      gridView: 'Grid Views',
      'virtual grid': 'Views',
      space: 'Spaces',
      form: 'Forms',
      block: 'Pages',
      page: 'Pages',
      spreadsheet: 'Spreadsheet Views',
      kanban: 'Kanban Views',
      calendar: 'Calendar Views',
      map: 'Map Views',
      gallery: 'Gallery Views',
      externalHook: 'Links',
      undefined: 'Miscellaneous',
    },
    proofread: {
      undo: {
        tooltip: 'Undo'
      },
      friendly: {
        tooltip: 'Make text more friendly'
      },
      professional: {
        tooltip: 'Make text more professional'
      },
      rewrite: {
        tooltip: 'Rewrite the text'
      },
      proofread: {
        tooltip: '}Proofread the text'
      },
    },
    voiceRecorder: {
      start: 'START',
      pause: 'PAUSE',
      continue: 'CONTINUE',
      finish: 'Done',
      allowMicrophone: 'Please allow microphone access to record audio',
      loading: 'Loading voice recorder...'
    },
    codeMirror: {
      shortcutHint: {
        prefix: 'Press',
        ctrlKey: 'Ctrl',
        spaceKey: 'Space',
        suffix: 'to insert grid fields'
      }
    },
    icon: {
      fileIcon: 'Icon file'
    },
  },
  de: {
    dialogs: {
      doneButton: 'Fertig',
      okButton: 'Ok',
      deleteButton: 'Löschen',
      areYouSure: 'Sind Sie sicher ?',
      cancelButton: 'Abbrechen',
      saveButton: 'Speichern',
      sendButton: 'Senden',
      openButton: 'Öffnen',
      createButton: 'Erstellen',
      closeButton: 'Schließen',
      retryButton: 'Wiederholen',
      deleteColumnTitle: '<p>Spalte&nbsp;<span class="red--text">{name}</span>&nbsp;löschen</p>',
      deleteGridButton: 'Grid löschen',
      deleteGridTitle: '<p><span class="red--text">{gridTitle}</span>&nbsp;löschen</p>',
      deleteFormButton: 'Formular löschen',
      deleteFormTitle: '<p>Formular&nbsp;<span class="red--text">{formTitle}</span>&nbsp;löschen</p>',
      deleteFormInfo:
        'Ihr Freigabelink funktioniert nach dem Löschen nicht mehr.',
      deleteVirtualGridButton: 'Ansicht löschen',
      deleteVirtualGridTitle: 'Ansicht löschen',
      deleteVirtualGridInfo:
        'Ihre erstellten Formulare funktionieren nach dem Löschen nicht mehr.',
      editEntity: 'Eintrag bearbeiten',
      editEntityTitle: 'Eintrag aus',
      createEntityTitle: 'Eintrag erstellen',
      renameGridButton: 'Grid umbenennen',
      renameGridTitle: '<p><span class="primary--text">{gridTitle}</span>&nbsp;umbenennen</p>',
      renameGridText: 'Geben Sie einen neuen Namen für dieses Grid ein',
      deleteSpace: 'Space löschen',
      deleteSpaceTitle: '<p><span class="red--text">{spaceTitle}</span>&nbsp;löschen</p>',
      renameSpaceButton: 'Space umbenennen',
      renameSpaceTitle: '<p><span class="primary--text">{spaceTitle}</span>&nbsp;umbenennen</p>',
      renameSpaceText: 'Geben Sie einen neuen Namen für dieses Space ein',
      shareSpaceButton: 'Space teilen',
      shareSpaceTitle: '<p>Teilen Sie&nbsp;<span class="primary--text">{spaceTitle}</span>&nbsp;mit anderen</p>',
      shareSpaceText: 'Senden Sie Ihrem Team eine Einladung per Mail',
      shareSpaceSubmit: 'Einladen',
      shareSpaceEmailPlaceHolder: 'E-Mail',
      createSpace: 'Einen Space erstellen',
      createSpaceText:
        'Lassen Sie uns mit einem Namen für Ihren Space beginnen',
      createGrid: 'Grid erstellen',
      createGridText: 'Wählen Sie nun einen Namen für Ihr erstes Grid',
      createGridMenu: {
        title: 'Ein leeres Grid erstellen',
        importFromData: 'Grid aus Daten importieren'
      },
      stayOnSpacesButton: 'Später',
      renameVirtualGridButton: 'Ansicht umbenennen',
      renameVirtualGridTitle: '<p><span class="primary--text">{virtualGridTitle}</span>&nbsp;umbenennen</p>',
      renameVirtualGridText: 'Geben Sie einen neuen Namen für diese Ansicht ein.',
      renameFormButton: 'Formular umbenennen',
      renameFormTitle: '<p><span class="primary--text">{formViewTitle}</span>&nbsp;umbenennen</p>',
      renameFormText: 'Geben Sie einen neuen Namen für dieses Formular ein.',
      setTime: 'Zeit wählen',
      renameAttachmentTitle: '<p><span class="primary--text">{title}</span>&nbsp;umbenennen</p>',
      renameAttachmentText: 'Geben Sie einen neuen Namen für diesen Anhang ein:',
      editFieldKeyTitle: 'Key der Spalte ändern',
      editFieldKeyText: 'Ein Key ist ein lesbarer Identifier einer Spalte. Sie können verwendet werden um ein Grid mit einer App zuverbinden.',
      gridKeysDialogButton: 'Felder Keys verwalten',
      gridKeysTitle: '<p><span class="primary--text">{gridTitle}</span>&nbsp;Felder Keys verwalten</p>',
      copySpaceTitle: '<p><span class="primary--text">{spaceName}</span>&nbsp; duplizieren</p>',
      copySpaceText: 'Geben Sie einen neuen Namen für diesen Space ein',
      copySpaceButton: 'Space duplizieren',
      copyOf: 'Kopie von {spaceName}',
      copy: 'Duplizieren',
      advancedSettings: 'Advanced Settings',
      setSpaceKeyTitle: '<p><span class="primary--text">{spaceName}</span> Key verwalten</p>',
      setSpaceKeyText: 'Ein Key ist ein lesbarer Identifier für dieses Space. Sie können verwendet werden um ein Grid mit einer App zuverbinden.',
      setSpaceKeyButton: 'Space Key verwalten',
      setGridKeyTitle: '<p><span class="primary--text">{gridTitle}</span>\'s Key verwalten</p>',
      setGridKeyText: 'Ein Key ist ein lesbarer Identifier für dieses Grid. Er kann verwendet werden um ein Grid mit einer App zuverbinden.',
      setGridKeyButton: 'Grid Key verwalten',
      setSpaceBelongsToTitle: '<p><span class="primary--text">{spaceName}</span>\'s Kategorie verwalten</p>',
      setSpaceBelongsToText: 'Legen Sie die Kategorie für diesen Space fest',
      setSpaceBelongsToButton: 'Space Kategorie verwalten',
      extractFieldTitle: 'Spalten in neues Grid bewegen',
      extractFieldText: 'Wählen Sie die Spalten aus, die Sie in ein neues Grid verschieben möchten. Im ursprünglichen Grid wird eine Verknüpfung erstellt',
      extractFieldGridNameText: 'Geben Sie einen Namen für das neue Grid ein',
      extractFieldButton: 'In neues Grid bewegen',
      gridFromCsvTitle: 'Eine CSV importieren',
      gridFromCsvText: 'Ein neues Grid aus einer CSV-Datei erstellen',
      gridFromCsvButton: 'CSV-Datei importieren',
      gridFromCsvFileInputLabel: 'CSV-Datei',
      gridFromCsvHasHeaderLabel: 'CSV-Kopfzeile ?',
      gridFromCsvHasHeaderHint: 'Enthält die erste Zeile der CSV Datei die Namen der Spalten?',
      more: 'Mehr',
      copyData: 'Zeilen kopieren',
      copyEntryUri: 'URL kopieren',
      signatureTitle: 'Hier unterschreiben',
      signatureClearButton: 'Korrigieren',
      signatureSaveButton: 'Speichern',
      spaceIcon: {
        title: 'Space Icon festlegen',
        menuItem: 'Space Icon festlegen'
      },
      camera: {
        permissionText: 'Bitte erlauben Sie den Kamerazugriff, um eine Aufnahme zu starten.',
        permissionButton: 'Kamerazugriff aktivieren'
      },
      apptiveTeams: {
        title: 'Bitte verwenden Sie die Apptive Teams App',
        text: 'Dieser Inhalt ist nur über die Apptive Teams App zugänglich. Bitte laden Sie die App herunter und melden Sie sich dort an, um fortzufahren.',
        downloadText: 'Laden Sie Apptive Teams jetzt herunter, um auf Ihren Inhalt zuzugreifen:'
      },
      blockKeysTitle: {
        title: 'Workflow-Keys verwalten',
        text: 'Keys verknüpfen Workflow-Schritte mit Formularfeldern.'
      },
      codeMirror: {
        shortcutHint: {
          prefix: 'Drücken Sie',
          ctrlKey: 'Strg',
          spaceKey: 'Leertaste',
          suffix: 'um Grid-Felder einzufügen'
        }
      }
    },
    forms: {
      builder: {
        title: 'Formularfelder',
        remainingFields: 'Weitere Formularfelder',
        addFieldsButton: 'Felder Hinzufügen',
        createFieldButton: 'Feld erstellen',
        addButton: 'Hinzufügen',
        label:'Label',
        description:'Beschreibung',
        headerImageLabel: 'Hintergrundbild',
        formTitleLabel: 'Titel des Formulars',
        formTitlePlaceholder: 'Titel hinzufügen...',
        descriptionLabel: 'Beschreibung',
        descriptionPlaceholder: 'Beschreibung hinzufügen...',
        formFieldLabelPlaceholder:'Text eingeben...',
        textsSectionHeader: 'Texte',
        imageSectionHeader: 'Bilder und Logo',
        colorSectionHeader: 'Farben',
        logoImageLabel: 'Logo',
        logoWidthLabel: 'Breite',
        logoWidthHint: 'Breite des Logos in Prozent',
        moreSettingsSection: 'Weitere Einstellungen',
        primaryColorLabel: 'Hauptfarbe',
        buttonTitleLabel: 'Absenden-Button Text',
        buttonColorLabel: 'Button Farbe',
        backgroundColorLabel: 'Hintergrundfarbe',
        cardColorLabel: 'Hintergrundfarbe Karte',
        headerColorLabel: 'Farbe Kopfzeile',
        removeCardShadowLabel: 'Karte ohne Schatten',
        titleSuccesPageHeader: 'Erfolgsseite',
        formSuccessTitleLabel: 'Titel',
        successMessageLabel: 'Nachricht',
        formAdditionalAnswerLabel: 'Text \'Weiteres Formular senden\'',
        pagesTabTitle: 'Seiten',
        styleTabLabel: 'Layout',
        moreTabLabel: 'Mehr',
        deletePageButtonTitle: 'Seite entfernen',
        addPageButton: 'Seite zum Formular hinzufügen',
        page: 'Seite',
        textBlock: 'Text',
        createTextBlockButton: 'Textblock erstellen',
        newTextBlock: 'Ein Textblock',
        textBlockDetails: {
          textInput: 'Ein Textblock',
          styles: {
            HEADER: 'Überschrift',
            PARAGRAPH: 'Absatz'
          }
        },
        adsSwitchLabel: 'Produkt Hinweis ausblenden',
        afterSubmitAction: {
          actionLabel: 'Aktion',
          triggerLabel: 'Auslösen durch',
          formRedirectUrlLabel: 'Webseite',
          formRedirectUrlPlaceholder: 'https://www...',
          redirectButton: 'Button Text',
          sendMoreButton: 'Button Text',
          redirectButtonPlaceholder: 'Button Text',
          additionalAnswer: 'Erneut ausfüllen',
          redirect: 'Auf Webseite weiterleiten',
          none: 'Keine Aktion',
          triggerButton: 'Button klicken',
          triggerAuto: 'Direkt nach absenden',
          triggerDelay: 'nach 5 Sekunden',
        },
        saveForLater:{
            featureHeadline: 'Speichen und später fortfahren',
            featureToggleLabel: 'Speichern um später fortzufahren',
            featureDescription: 'Die Befragten können später zurückkehren, dort weitermachen, wo sie aufgehört haben, und das Formular problemlos ausfüllen, ohne dass ihre zuvor eingegebenen Daten verloren gehen.',
            formButtonTitle: 'Später fortfahren',
            formSuccessButtonTitle: 'Formular wurde gespeichert!',
            restoreFormTitle: 'Das Formular wurde gespeicher aber noch nicht gesendet!',
            restoreFormSubtitle: 'Sie können das Formular später fertig ausfüllen.',
            deleteAllButton: 'Alle löschen',
            closeButton: 'Fertig'
        },
        autoSubmit: {
          featureHeadline: 'Automatisches Absenden beim Laden',
          featureToggleLabel: 'Automatisch absenden',
          featureDescription: 'Aktivieren Sie diese Funktion, um das Formular automatisch zu übermitteln, sobald es geladen wird. Starten Sie damit einen Workflow oder erfassen Sie einen QR-Code-Scan.',
        },
        hideSubmitButton: {
          featureHeadline: 'Absenden-Button ausblenden',
          featureToggleLabel: 'Absenden-Button ausblenden',
          featureDescription: 'Aktivieren Sie diese Funktion, um den Absenden-Button auf dem Formular auszublenden. Dies ist nützlich, wenn Sie das Formular nutzen um eine Ansicht zu einem Eintrag mit Anderen zu teilen.',
        },
        i18n: {
          featureHeadline: 'Mehrere Sprachen',
          featureToggleLabel: 'Mehrere Sprachen',
          featureDescription: 'Aktivieren Sie diese Funktion, um mehrere Sprachen für Ihr Formular zu unterstützen.',
          buttonLabel: 'Übersetzungen bearbeiten',
          dialog: {
            title: 'Formular-Spracheditor',
            defaultLanguage: 'Standardsprache',
            backButton: 'zurück',
            defaultLanguageDescription: 'Dies ist die Sprache, die den im Formular-Builder bereits eingerichteten Texten entspricht.',
            additionalLanguages: 'Zusätzliche Sprachen',
            additionalLanguagesDescription: 'Sie können hier eine oder mehrere zusätzliche Sprachen hinzufügen.',
            currentLanguage: 'Aktuelle Sprache',
            globalTexts: 'Globale Formulartexte',
            componentTexts: 'Formular Felder',
            textBlocks: 'Textblöcke',
            globalTextLabels: {
              title: 'Formular Titel',
              description: 'Formular Beschreibung',
              buttonTitle: 'Absenden Button',
              successTitle: 'Überschrift Erfolgsseite',
              successMessage: 'Nachricht Erfolgsseite',
              afterSubmitActionButtonTitle: 'Button Erfolgsseite'
            }
          }
        },
        fieldStyleSectionHeader: 'Style der Felder',
        fieldStyle_default: 'Ohne Rahmen',
        fieldStyle_outlined: 'Mit Rahmen'
      },
      shareForm: {
        title: 'Teilen deines Formulars',
        text:
          'Teile dein Formular mit Anderen. Dein Formular haben wir sicher veröffentlicht.',
        tabs: {
          qrCode: 'QR-Code',
          qrCodeText:'Scanne diesen QR-Code mit der Kamera deines Smartphones, um das Formular direkt zu öffnen. Alternativ lade den QR-Code als SVG oder PNG herunter, um ihn weiterzuverwenden.',
          link: 'Link',
          openInApp: 'In App öffnen',
          openInAppExplanation: 'Lade die App herunter und scanne den QR-Code, um dieses Formular zu deiner Apptive Launcher App hinzuzufügen.',
          appStoreBadgeSrc: 'app_store_en.svg',
          playStoreBadgeSrc: 'google-play-badge-en.png',
        },
        buttons: {
          copy: 'Kopieren',
          open: 'Vorschau in neuem Tab',
          openPrefillEditor: 'Formular vorausgefüllt teilen',
          addLink: 'Link hinzufügen',
          qrCode: 'QR-Code',
          setRestrictions: 'Beschränkungen festlegen'
        },
        embed: {
          open: 'Auf Ihrer Webseite einbinden',
          title: 'Eingebundene Ansicht',
          subtitle: 'Formular',
          autoresizeTitle: 'Höhe automatisch anpassen',
          autorisizeDescription: 'Die Höhe wird automatisch der an das Formular angepasst',
          codePaneHeader: 'Embed code',
          previewMobile: 'Vorschau Smartphone',
          previewDesktop: 'Vorschau Computer',
        },
        prefillEditor: {
          title: 'Erstellen Sie einen Link, um dieses Formular vorab auszufüllen.',
          subtitle: 'Geben Sie bitte die Daten in die entsprechenden Formularfelder ein. Kopieren Sie den generierten Link und nutzen Sie ihn, um das Formular mit den vorausgefüllten Daten schnell und einfach zu teilen.',
          close: 'Schließen'
        },
        addLinkDialog: {
          title: 'Neuer Link',
          text: 'Bitte wählen Sie einen Namen für den Link',
          formSelectHint: 'Bitte wählen Sie ein Formular'
        },
        restrictions: {
          title: 'Zugriffsbeschränkungen festlegen',
          text: 'Legen Sie fest, wer über diesen Link auf das Formular zugreifen darf.',
          anyone: 'Jeder',
          anyoneDescription: 'Jeder mit diesem Link kann darauf zugreifen.',
          onlyUsers: 'Jeder registrierte Benutzer',
          onlyUsersDescription: 'Nur registrierte Benutzer können mit diesem Link darauf zugreifen.',
          onlyCollaborators: 'Jeder aus diesem Space',
          onlyCollaboratorsDescription: 'Nur wer zu diesem Space eingeladen wurde, kann darauf zugreifen.',
        }
      },
      builderFormField: {
        required: 'Pflichtfeld',
        removeButton: 'Entfernen',
        editButton: 'Bearbeiten',
        doneButton: 'Fertig',
        multiline: 'Mehrzeilig',
        back: 'zurück zum Formular',
        componentType: 'Komponententyp',
        defaultValueTitle: 'Vorausgefüllt',
        componentTypes: {
          selectBox: 'Dropdown-Menü',
          selectList: 'Liste',
          multiSelectDropdown: 'Dropdown-Menü',
          multiSelectList: 'Liste',
          videoRecorder: 'Video aufnehmen',
          filePicker: 'Datei auswählen'
        },
        disabled: 'Nicht änderbar',
        hidden: 'Nicht sichtbar',
        fieldSettings: 'Feldeinstellungen'
      },
      view: {
        submitButton: 'Absenden',
        nextPageButton: 'Weiter',
        submitMoreButton: 'Weitere Antworten senden',
        backButton: 'Zurück zum Formular',
        successTitle: 'Vielen Dank!',
        successMessage: 'Ihre Antwort ist bei uns eingegangen.',
        errorTitle: 'Ups - Fehler',
        previousPageButton: 'zurück',
        tapToSign: 'Hier tippen zum Unterschreiben',
        tapToRecord: 'Hier tippen zum Aufnehmen',
        uploading: 'Sprachaufnahme wird hochgeladen'
      },
      formSelectList: {
        otherPlaceholder: 'Oder geben Sie eine andere Auswahl ein'
      },
      shareButton: 'Teilen',
      advertisement: 'Erstell dein eigenes kostenloses Formular mit ',
      defaultFormTitle: 'Formular',
      country: 'Land',
      securityHintPassword: 'Geben Sie nie Ihr Passwort preis.',
      abuse: {
        actionTitle: 'Formular melden',
        title: 'Missbräuchliches Formular melden',
        subtitle: 'Bitte beschreiben Sie kurz, wie dieses Formular verwendet wird. Unser Team wird Ihren Bericht so schnell wie möglich überprüfen.',
        placeholder: '(Optional) beschreiben Sie bitte kurz, wie dieses Formular verwendet wird.',
        button: 'Meldung absenden',
        cancel: 'Abbrechen',
        warning: {
          title: 'Warnung: Potenziell riskantes Formular',
          subtitle: 'Das von einem Benutzer erstellte Formular, auf das Sie zugreifen möchten, wurde als potenzielles Risiko eingestuft.\n\nWenn Sie dennoch fortfahren und das Formular anzeigen möchten, denken Sie bitte daran, niemals Ihre Passwörter über Formulare einzugeben.\n\nWenn Sie eine Überprüfung dieses Formulars beantragen möchten, kontaktieren Sie bitte abuse@apptivegrid.com.',
          button: 'Dennoch öffnen'
        },
        blocked: {
          title: 'Dies Formular ist gesperrt',
          subtitle: 'Das von einem Benutzer erstellte Formular, auf das Sie zugreifen möchten, wurde als Risiko eingestuft und gesperrt.\n\nWenn Sie eine Überprüfung dieses Formulars beantragen möchten, kontaktieren Sie bitte abuse@apptivegrid.com.',
        }
      },
    },
    webhooks: {
      title: 'Webhooks',
      flowTitle: 'Den Flow auslösen, wenn',
      description: 'Webhooks sind automatische Nachrichten, die gesendet werden, wenn etwas passiert. Sie haben eine Nachricht oder Payload und werden an eine eindeutige URL gesendet. ',
      nameLabel: 'Name des Webhooks',
      createHookTitle: 'Webhook',
      defaultWebhookTitle: 'Mein Webhook',
      buttons: {
        create: 'Webhook erstellen',
        save: 'Speichern',
        delete: 'Löschen',
        edit: 'Bearbeiten',
      },
      trigger: {
        chooseSpace: 'Space auswählen',
        chooseGrid: 'Grid auswählen',
        chooseEvent: 'Event auswählen',
        entityAdded: 'Neuer Eintrag',
        entityUpdated: 'Eintrag verändert',
        entityAddedEvent: 'Neuer Eintrag',
        entityUpdatedEvent: 'Eintrag verändert',
        chooseForm: 'Formular auswählen',
        addEntityFormSubmitted: 'Neuer Eintrag per Formular',
        editEntityFormSubmitted: 'Eintrag verändert per Formular',
        deprecated: 'veraltet'
      },
    },
    links: {
      title: 'Links',
      description: 'Hier ist eine Liste aller externen Links in diesem Space',
      types: {
        spaceInvitation: 'Space-Einladung',
        externalFlowTrigger: 'Flow starten',
        addEntity: 'Eintrag hinzufügen',
        editEntity: 'Eintrag bearbeiten',
        block: 'Seite',
        flowInstance: 'Flow Ausführung'
      },
      shareView: {
        restrictions: {
          title: 'Zugriffsberechtigungen einstellen',
          text: 'Legen Sie fest, wer Zugriff hat',
          anyone: 'Alle',
          anyoneDescription: 'Jede Person mit dem Link kann zugreifen',
          usernamePassword: 'Mit Anmeldeinformationen',
          usernamePasswordDescription: 'Zugriff nur mit Benutzername und Passwort',
          username: 'Benutzername',
          password: 'Passwort'
        },
        success: {
          title: 'Berechtigungen aktualisiert',
          message: 'Die Zugangsdaten wurden erfolgreich zum Link hinzugefügt.',
          textBold: 'Bitte beachten',
          textBody: 'Aus Sicherheitsgründen ist es nicht möglich, den Benutzernamen und das Passwort später einzusehen. Sie können diese jedoch jederzeit neu setzen. Bewahren Sie die Zugangsdaten sicher auf und teilen Sie sie nur mit Personen, denen Sie vertrauen.',
          usernameFieldLabel: 'Benutzername',
          passwordFieldLabel: 'Passwort'
        }
      }
    },
    validation: {
      notEmpty: 'Darf nicht leer sein',
      emailRequired: 'Die E-Mail-Adresse darf nicht leer sein',
      emailError: 'Gültige E-Mail-Adresse notwenig',
      passwordRequired: 'Das Password darf nicht leer sein',
      passwordError:
        'Das Password benötigt einen kleinen Buchstaben, einen großen Buchstaben, eine Zahl und ein Sonderzeichen: ()[]{}?!$%&/=*+~#,.;:@<>_-',
      nameRequired: 'Der Name darf nicht leer sein',
      maxLength: 'Der Name kann maximal {max} Zeichen lang sein. ',
      nameExistsError: 'Der Name existiert bereits',
      optionNotEmpty: 'Die Option darf nicht leer sein',
      noDuplicate: 'Die Option darf kein Duplikat sein',
      required: 'Bitte füllen Sie dieses Pflichtfeld aus.',
      integer: 'Es sind nur Zahlen (0-9) erlaubt',
      decimal: 'Es sind nur Gleitkommazahlen erlaubt',
      coordinates: 'Muss das Format Latitude, Longitude haben (z.B. 50.12,6.543)',
      spaceAlreadyShared: 'Dieser Space wird bereits mit diesem Benutzer geteilt',
      cannotShareToSelf: 'Spaces können nicht mit sich selbst geteilt werden',
      uri: 'Bitte geben Sie eine gültige URL ein',
      invalidPhoneNumber: 'Bitte geben Sie eine gültige Telefonnummer ein',
      phoneNumberInternationalIndicator: 'Internationale Vorwahl ist erforderlich (z.B. +49)',
      address: 'Bitte füllen Sie die erforderlichen Unterfelder aus'
    },
    filterToolBar: {
      filter: 'Filter | {count} Filter | {count} Filter',
      add: 'Filter hinzufügen',
      allFiltersValidHint: 'Alle vorhandenen Filter müssen gültig sein',
      addGroup: 'Filtergruppe hinzufügen',
      maxNestingLevelHint: 'Filterbedingungen können nur 3 Ebenen tief verschachtelt werden',
    },
    sorting: {
      menuButtonLabel: 'Sortieren | {count} Sortierung | {count} Sortierungen',
      orderLabel: 'Ordnung',
      ascending: 'aufsteigend',
      descending: 'absteigend',
      distanceTo: 'Entfernung nach',
      addCriteria: 'Ein weiteres Feld zum Sortieren hinzufügen'
    },
    grouping: {
      menuButtonLabel: 'Gruppieren',
      groupBy: 'Gruppieren nach',
      count: 'Anzahl ',
      noFieldAvailable: 'Kein verfügbares Feld für die Gruppierung'
    },
    navigationDrawer: {
      createViewHeader: 'Erstellen',
      newVirtualGrid: 'Ansicht erstellen',
      newForm: 'Formular erstellen',
      viewsHeader: 'Ansichten',
      formsHeader: 'Formulare'
    },
    spaceMenu: {
      pages: 'Seiten',
      flows: 'Flows',
      grids: 'Grids',
      home: 'Alle Spaces'
    },
    kanban: {
      kanbanBoard: 'Kanban Board',
      kanbanBoardShort: 'Kanban',
      onboarding: {
        title: 'Kanban Boards',
        subtitle: 'Übersicht über Ihre Arbeitsprozesse',
        text: 'Lassen sie sich ihre Einträge als Karten auf einen Kanban-Board darstellen.'
      },
      settings: {
        menuButton: 'Einstellungen',
      },
      unstacked: 'UNGRUPPIERT',
      unstackedButton: 'Ungruppierte Karten einblenden',
      hideUnstackedButton: 'Ungruppierte Karten ausblenden',
      unnamedEntry: 'Eintrag unbenannt',
      stateSelection: {
        activtor: 'Gruppiert nach',
        title: 'Wählen Sie ein Gruppierungsfeld',
        text: 'Jede Option repräsentiert eine Spalte des Kanban-Boards',
        createTitle: 'Ein Gruppierungsfeld erstellen',
        noEnum: 'Um eine Kanban Board zu sehen, muss Ihr Grid eine Spalte vom Typ Einzelauswahl besitzen. Jede Option repräsentiert eine Spalte des Kanban-Boards',
        submitNewEnum: 'Feld erstellen'
      }
    },
    gallery: {
      title: 'Gallerie',
      settings: {
        menuButton: 'Einstellungen',
        imageMode: 'Bild',
        cardSize: 'Kartengröße',
        fieldIcon: 'Feld Icon',
        fieldTitle: 'Feld Name',
        firstImage: 'Erstes Bild',
        noImage: 'Kein Bild',
        small: 'Klein',
        medium: 'Mittel',
        large: 'Groß',
      },
      onboarding: {
        title: 'Gallerie',
        subtitle: 'Übersicht auf deine Daten',
        text: 'Stelle deine Daten als Karten einer Gallerie dar.'
      },
    },
    list: {
      title: 'Liste'
    },
    virtualGrid: {
      addGridButton: 'Grid erstellen',
      view: 'Ansicht',
      defaultName: 'Ansicht',
      columnNameLabel: 'Spaltenname',
      advancedFieldSettings: 'Erweiterte Einstellungen',
      selectButton: 'Auswählen',
      enumOptionsTitle: 'Auswahl Einstellungen',
      enumOptionsInputPlaceholder: 'Neue Option',
      enumOptionsExtendedLabel: 'Andere Optionen zulassen',
      enumFilterPlaceholder: 'Eine Option finden',
      enumOtherOption: 'Sonstige Eingabe',
      crossReferenceOptionsTitle: 'Wählen Sie die Ansicht aus der Zeilen verknüpft werden können.',
      currencyOptionsTitle: 'Wählen Sie eine Währung',
      resourceOptionsTitle: 'Wählen Sie einen oder mehrere Ressourcentypen (optional)',
      rowFiltered: 'Zeile gefiltert',
      noMatchingEntry: 'Kein passender Eintrag gefunden.',
      newField: 'Neues Feld ',
      sum: 'Summe',
      addEntry: '{fieldName} auswählen',
      createEntry: 'Neuen Eintrag erstellen',
      qrCode: {
        buttonText: 'Teilen',
        dialog: {
          title: '<span><span class="primary--text">{viewName}</span>&nbsp;Teilen</span>',
          subtitle: 'Teile deine Ansicht mit Anderen und arbeite von überall.',
          qrcode: 'QR Code',
          openInApp: 'In App öffnen',
          openInAppExplanation: 'Lade die App herunter und scannen den QR-Code, um diese Ansicht zu deiner mobilen App hinzuzufügen.',
          appStoreBadgeSrc: 'app_store_de.svg',
          playStoreBadgeSrc: 'google-play-badge-de.png',
          link: 'Link',
          linkExplanation: 'Öffne diesen Link auf deinem Handy, um die Ansicht zu deiner Apptive Launcher App hinzuzufügen.',
          buttons: {
            copy: 'Kopieren',
            open: 'Link öffnen',
            remove: 'Entfernen'
          }
        }
      },
      shareDialog: {
        notShared: {
          title: 'Erstellen Sie einen freigebbaren Link zur Ansicht',
          explanation: 'Sie können den Link an eine beliebige Person senden oder in eine Website einbetten.',
        },
        shared: {
          title: 'Diese Ansicht wird über einen privaten Link freigegeben',
          explanation: 'Über diesen Link sind Daten dieser Ansicht sichtbar.',
          addForm: 'Bitte wählen Sie optional ein Formular aus, um die Erstellung neuer Einträge zu ermöglichen.'
        },
        buttons: {
          share: 'Teilen'
        }
      },
      filter: {
        conditionalWhere: 'Wenn',
        contains: 'enthält',
        fieldNameLabel: 'Feld',
        fieldValueLabel: 'Wert',
        conditionSelectLabel: 'Zustand',
        hideColumnButton: 'Ausblenden',
        hidenColumnsButton: 'ausgeblendet',
        equalCondition: 'ist gleich',
        notEqualCondition: 'ist nicht gleich',
        lessCondition: 'ist kleiner als',
        greaterCondition: 'ist größer als',
        anyOf: 'enthält eines von',
        noneOf: 'enthält keines von',
        allOf: 'enthält alle von',
        isEmpty: 'ist leer',
        isNotEmpty: 'ist nicht leer',
        doesNotContain: 'enthält nicht',
        isActor: 'ist',
        isNotActor: 'ist nicht',
        beforeCondition: 'ist vor',
        afterCondition: 'ist nach',
        noAvailableFilter: 'Kein verfügbarer Filter',
        explanation: 'In dieser Ansicht Einträge anzeigen',
        composite: {
          label: 'Kombination',
          and: 'und',
          or: 'oder',
          orExplanation: 'Jeder der folgenden Punkte ist wahr...',
          andExplanation: 'Alle der folgenden Punkte sind zutreffend...'
        }
      },
      rowMoreMenu: {
        share: 'Eintrag teilen'
      },
      moreMenu: {
        csvExportButton: 'Als CSV exportieren',
        cloneVirtualGrid: 'Ansicht duplizieren'
      },
      shareEntity: {
        title: 'Formular zum bearbeiten teilen',
        subtitle: 'Teile ein Formular zum bearbeiten des Eintrags mit Anderen.',
        textShared: 'Dein Eintrag haben wir sicher veröffentlicht und ist unter der folgenden Webadresse abrufbereit.',
        textNoForm: 'Erstellen Sie erst ein Formular um den Eintrag mit anderen zu teilen.',
        selectForm: 'Wählen Sie ein Formular aus'
      },
      batchDelete: {
        openDialogButton: '{numberOfEntities} löschen',
        cancel: 'Abbrechen',
        confirmDelete: 'Löschen',
        titel: '{numberOfEntities} Einträge löschen?',
      }
    },
    user: {
      userDevSwitch: 'Probieren Sie unsere neuesten Funktionen aus, indem Sie zum Beta-Modus wechseln'
    },
    columnTypes: {
      text: 'Text',
      richText: 'Formatierter Text',
      number: 'Zahl',
      decimal: 'Kommazahl',
      dateAndTime: 'Datum und Uhrzeit',
      date: 'Datum',
      checkmark: 'Häkchen',
      singleSelect: 'Einzelauswahl',
      enumCollection: 'Mehrfachauswahl',
      crossReference: 'Link zur Zeile eines anderen Grids',
      crossReferences: 'Links zu mehreren Zeilen eines anderen Grids',
      attachments: 'Anhänge',
      geolocation: 'Geolokalisierung',
      address: 'Adresse',
      createdBy: 'Erstellt von',
      createdAt: 'Erstellt am',
      user: 'Benutzer',
      currency: 'Währung',
      uri: 'URL',
      email: 'E-Mail',
      phoneNumber: 'Telefonnummer',
      signature: 'Unterschrift',
      lookup: 'Lookup',
      sumup: 'Sum-Up',
      formula: 'Formel',
      resource: 'Interner Link'
    },
    views: {
      previewButton: 'Vorschau',
      spacesTitle: 'Spaces',
      logoutButton: 'Abmelden',
      reloadButton: 'Ansicht neu laden',
      updateInfo: 'Dieses Grid wurde aktualisiert'
    },
    spaces: {
      greetings: {
        early_morning: 'Guten Morgen {name} ☕',      // for early morning (until 10am)
        late_morning: 'Hallo {name} 👋',              // for late morning (10am-12pm)
        afternoon: 'Willkommen {name} 👋',            // for afternoon
        evening: 'Guten Abend {name} 👋'              // for evening
      },
      createNewSpaceButton: 'Neuen Space erstellen',
      emptyStateHeadline: 'Erstelle deinen ersten Space',
      allSpaces: 'Alle Spaces',
      sharedWithMe: 'Mit mir geteilt',
      searchPlaceholder: 'Space suchen...',
      groupByBelongsTo: 'Nach Kategorie gruppieren',
      noGroup: 'Keine Kategorie'
    },
    csvImport: {
      buttons: {
        openComponent: 'Aus CSV importieren',
        loading: 'Laden...'
      },
      componentTitle: 'CSV Import',
      steps: {
        loadFile: {
          title: 'CSV Datei einlesen',
          fileFormatConstrainsInfo: 'Die CSV Datei muss im UTF-8 Format erstellt sein.',
          hasHeaderRow: 'Kopfzeile?',
          fileFormatInvalid: 'Das Dateiformat ist ungültig.',
          loadFileButton: 'Datei Laden',
          loadCSVButton: 'CSV einlesen',
          mappingTableHeaderGrid: 'Grid',
          mappingTableHeaderCSV: 'CSV',
          ignoreOptionText: 'Ignorieren'
        },
        preview:{
          title: 'Vorschau',
          importButton: 'Daten importieren'
        },
        import: {
          title: 'Importieren',
          progressDescription: 'Es werden {numberOfEntities} Einträge importiert.'
        },
        done: {
          title: 'Abgeschlossen',
          result: 'Es wurden {numberOfImportedEntities} Einträge erfolgreich importiert.'
        }
      }
    },
    settings: {
      spaceSettingsTitle: 'Space Einstellungen',
      apiCredentialsNavigation: 'API Zugangsdaten',
      accountSettingsButton: 'Profil & Einstellungen',
      profileNavigationTitle: 'Profil',
      accountSettingTitle: 'Profil & Einstellungen',
      webhooks: 'Webhooks',
      payment: 'Abrechnung',
      links: 'Links',
      generalSpaceSettings: {
        title: 'Allgemein',
        description: 'Allgemeine Einstellungen zu diesem Space',
        spacePlan: 'Dieser Space ist im Plan',
        spacePlanDescription: 'Der Eigentümer kann den Plan anpassen.',   
        changePlanButton: 'Plan und Abonnement anpassen',
        info: 'Info',
        spaceId: 'Space Identifikator',
        createdAt: 'Erstellt am',
        availableSeats: 'Verfügbare Plätze für NutzerInnen mit Bearbeitungsrechten: ',
        availableSeatsDescription: 'Der Eigentümer kann zusätzliche Plätze für NutzerInnen mit Bearbeitungsrechten erwerben. Leserechte sind kostenfrei.',
        changeAvailableSeatsButton: 'Plätze anpassen',
        trialPeriod: 'Test-Phase verbleibende Tage: ',
      }
    },
    createApiKey: {
      title: 'API Zugangsdaten',
      explanation: 'Dies sind Ihre persönlichen API Anmeldedaten. Sie werden benötigt, um die API zu nutzen. Ihre persönlichen API Anmeldeinformationen gewähren Zugriff auf alle Daten in Ihren Spaces. Geben Sie diese Anmeldeinformationen nur an Dienste und Anwendungen von Drittanbietern weiter, denen Sie vertrauen.',
      dialogTitle: 'Zugangsdaten erstellen',
      dialogText: 'Wählen Sie einen Namen für Ihre Zugangsdaten',
      dialogOkButtonLabel: 'Erstellen',
      buttonLabel: 'Zugangsdaten erstellen',
      roleSelectLabel: 'Diese Zugangsdaten gewähren :',
      adminDescription: 'Vollen Zugriff auf Ihre Spaces',
      readerDescription: 'Nur Lesezugriff auf Ihre Spaces',
      adminLabel: 'Admin',
      readerLabel: 'Nur lesen'
    },
    newApiCredentials: {
      title: 'API Zugangsdaten',
      successMessage: 'Ihre Zugangsdaten sind verfügbar',
      textBold: 'Speichern Sie jetzt Ihren Autorisierungsschlüssel und Ihr Passwort.',
      textBody: 'Dies ist der einzige Zeitpunkt, zu dem die Zugangsdaten angezeigt oder heruntergeladen werden können. Später können sie nicht mehr wiederhergestellt werden. Sie können Ihre API Zugangsdaten jedoch jederzeit löschen und neue erzeugen.',
      keyFieldLabel: 'Autorisierungsschlüssel',
      secretFieldLabel: 'Passwort'
    },
    errorMessages: {
      cannotRemoveReferencedObject: 'Löschen nicht möglich. Der Eintrag ist noch an einer anderen Stelle referenziert.',
      fieldReferencedByFilter: 'Änderung nicht möglich. Dieses Feld wird in einem Filter verwendet.',
      userWithEmailNotFound: 'Benutzer mit E-Mail {email} existiert nicht',
      nestedReferencesDetected: 'Links auf Grids die bereits an einer anderen Stelle verlinkt wurden, sind noch nicht möglich.',
      spaceAccessDenied: 'Sie haben keinen Zugriff mehr auf diesen Space.',
      spaceNotFound: 'Dieser Space wurde gelöscht',
      unrecognisedDate: 'Unerkanntes Datumsformat : {date}',
      unaccessibleView: 'Diese Ansicht ist nicht mehr zugänglich.',
      notAllowed: 'Nicht erlaubt',
      formNotAccessable: 'Zugriff auf dieses Formular ist eingeschränkt.',
      noNextFlowFormPage: 'Für diesen Schritt ist keine eigene UI hinterlegt. Falls du eine UI benötigst, kannst du sie im Flow Editor hinzufügen.'
    },
    calendar: {
      title: 'Kalender',
      newEvent: 'Neues Ereignis',
      types: {
        day: 'Tag',
        week: 'Woche',
        month: 'Monat',
      },
      today: 'Heute',
      onboarding: {
        title: 'Kalender',
        subtitle: 'Termine übersichtlich verwalten',
        text: 'Um eine Kalenderansicht zu sehen, muss dein Grid eine Spalte vom Typ Datum besitzen.'
      },
      stateSelection: {
        activtor: 'Kalender Einstellungen',
        title: 'Datumsfeld für Ereignisse festlegen',
        text: 'Welches Datumsfeld möchten Sie für diese Ansicht verwenden? Ihre Einträge werden auf der Grundlage der Daten in diesem Feld platziert.',
        createTitle: 'Ein Datumsfeld erstellen',
        noDate: 'Um einen Kalender zu sehen, muss Ihr Grid mindestens ein Datumsfeld besitzen.',
        submit: 'Speichern',
        start: 'Von',
        end: 'Bis (optional)',
      }
    },
    avatarEditor: {
      errors: {
        fileWrongFormat: 'Wähle ein Bild mit dem Format jpg.',
        fileSizeToBig: 'Die Datei ist zu groß. Wähle ein Bild kleiner als 1MB.'
      }
    },
    attachments: {
      pondLabel: 'Dateien hier ablegen... oder <u>zum Durchsuchen anklicken</u>',
      downloadButtonLabel: 'Herunterladen',
      renameButtonLabel: 'Umbenennen'
    },
    map: {
      title: 'Karte',
      searchHere: 'Hier suchen',
      onboarding: {
        title: 'Karte',
        subtitle: 'Standorte auf einen Blick.',
        text: 'Zeigen Sie Ihre Einträge als Markierungen auf einer Karte an.'
      },
      stateSelection: {
        activtor: 'Einstellungen',
        title: 'Wählen Sie ein Feld des Typs Geolokalisierung',
        text: 'Das Feld bestimmt die Position der Marker auf der Karte.',
        createTitle: 'Erstellen Sie ein Feld',
        noGeolocation: 'Um eine Karte zu sehen, muss Ihr Grid eine Spalte des Typs Geolokalisierung haben. Sie bestimmt die Position der Marker auf der Karte.',
        submitNewEnum: 'Erstellen'
      }
    },
    roles: {
      selectLabel: 'Der Nutzer kann:',
      owner: 'Eigentümer',
      admin: 'Managen',
      adminLabel: 'Managen',
      adminDescription: 'diesen Space bearbeiten und konfigurieren',
      reader: 'Nur ansehen',
      readerLabel: 'Ansehen',
      readerDescription: 'den Space nicht bearbeiten und konfigurieren',
      writer: 'Einträge bearbeiten',
      writerLabel: 'Bearbeiten',
      writerDescription: 'Inhalte bearbeiten aber nichts konfigurieren'
    },
    createdBy: {
      formLink: 'Formular-Link',
      accessKey: 'API Zugangsdaten'
    },
    spaceShareList: {
      header: 'Geteilt mit'
    },
    spaceInvitesList: {
      header: 'Offene Einladungen'
    },
    expressions: {
      exactDate: 'genaues Datum',
      exactValue: 'exakter Wert',
      today: 'heute',
      tomorrow: 'morgen',
      yesterday: 'gestern',
      daysAgo: 'vor Tagen',
      daysFromToday: 'Tage ab heute',
      loggedInUser: 'eingeloggter Benutzer',
      ownerEmail: 'E-Mail Space Eigentümer',
      ownerFirstName: 'Vorname Space Eigentümer',
      ownerLastName: 'Nachname Space Eigentümer',
      empty: 'Leerer Ausdruck',
      unknown: 'Unbekannter Ausdruck'
    },
    profile: {
      title: 'Profil',
      profileImage: 'Profilbild',
      firstName: 'Vorname',
      lastName: 'Nachname',
      email: 'E-Mail',
      delete: {
        buttonLabel: 'Account löschen',
        cancelButtonLabel: 'Abbrechen',
        description: 'Ihr Account und alle Daten werden unwiederruflich gelöscht. Geben Sie DELETE in das Textfeld ein um fortzufahren.'
      }
    },
    payment: {
      title: 'Abrechnungen und Preise',
      billing: {
        explanation: 'Verwalte Abonnements, lade Rechnungen und bearbeite Zahlungs- oder Rechnungsdaten',
        button: 'Rechnungen und Abonnement',
        currentPlan: 'Du bist akuell im Plan'
      },
    },
    templates: {
      title: 'Oder starten Sie mit einem Template'
    },
    flow: {
      flowlist: {
        buttons: {
          addFlow: 'Flow erstellen',
          createNewFlowButton: 'Flow erstellen',
        },
        labels: {
          emptyStateHeadline: 'Automatisiere deine Arbeit.',
        },
        allFlows: 'Alle Flows'
      },
      flowView: {
        editorTab: 'Editor',
        historyTab: 'Verlauf'
      },
      instanceList: {
        unknownDate: 'Datum unbekannt',
        labels: {
          started: 'Gestartet',
          steps: 'Steps',
          state: 'Status',
          open: 'Öffnen'
        },
        states: {
          done: 'Fertig',
          error: 'Fehler'
        }
      },
      editor: {
        testRunSnack: 'Der Flow ist bereit, getestet zu werden',
        errors: {
          testRunFailed: 'Flow hat noch Fehler',
          noPreviousRunFound: 'Es wurde keine vorherige ausführung gefunden.',
        },
        buttons: {
          runflow: 'Flow ausführen',
          testflow: 'Flow testen',
          waitforNewRunTitle: 'Warten',
          waitforNewRunDescription: 'Warten bis der Flow erneut ausgeführt wird.',
          reRunFlowTitle: 'Erneut starten',
          reRunFlowDescription: 'Mit Daten aus letzer Ausführung',
          testRunFlowTitle: 'Flow testen',
          testRunFlowDescription: 'Verwendet letzte Zeile des Grids',
          testRunWithPayloadTitle: 'Mit Test-Daten starten',
          testRunWithPayloadDescription: 'Gib Beipiel-Daten an',
          openPageAndWaitUntilNextRun: 'Mit Daten aus dem Flow Formular',
          openPageAndWaitUntilNextRunDescription: 'Das Formular öffnen und mit übermittelten Daten testen',
        },
        instanceInfo: {
          autoLoadedTitle: 'Anzeige vorheriger Ausführungen',
          testInstanceTitle: 'Testausführung',
          description: 'Dies sind Daten eines vorherigen Flow-Ausführungen. Sie wurden automatisch geladen, um Ihnen das Verhalten des Flows zu verdeutlichen.',
          runAt: 'Ausgeführt am',
          loadedAt: 'Geladen am',
          status: 'Status',
          steps: 'Schritte',
          selectOther: 'Andere auswählen',
          clear: 'Entfernen'
        },
        instanceSelection: {
          title: 'Ausführung auswählen',
          loading: 'Ausführungen werden geladen...',
          noInstances: 'Keine Ausführungen gefunden',
          instance: 'Ausführung',
          current: 'Aktuell',
          created: 'Erstellt',
          close: 'Schließen'
        },
        instanceStatus: {
          previousRun: 'Vorherige Ausführung',
          selectedInstance: 'Ausgewählte Ausführung',
          testInstance: 'Testausführung',
          completed: 'Abgeschlossen',
          failed: 'Fehlgeschlagen',
          inProgress: 'In Bearbeitung',
          created: 'Erstellt'
        }
      },
      nodeMenu: {
        rename: 'Umbenennen',
        delete: 'Löschen',
        configure: 'Konfigurieren',
        addConnection: 'Verbindung hinzufügen'
      },
      nodeIndicator: {
        showDetails: 'Details anzeigen',
        showErrorDetails: 'Fehlerdetails anzeigen'
      },
      nodes: {
        start: {
          name: 'Auslöser',
          description: 'Ein Event ist eingetreten'
        },
        entityAdded: {
          name: 'Neuer Eintrag',
          description: 'Ein neuer Eintrag wurde hinzugefügt'
        },
        entityUpdated: {
          name: 'Eintrag wurde geändert',
          description: 'Ein bestehender Eintrag wurde geändert'
        },
        addEntityFormSubmitted: {
          name: 'Eintrag per Formular',
          description: 'Neuer Eintrag per Formular hinzugefügt',
        },
        editEntityFormSubmitted: {
          name: 'Eintrag per Formular geändert',
          description: 'Bestehender Eintrag per Formular geändert'
        },
        end: {
          name: 'Ende',
          description: 'Etwas, das den Flow beendet'
        },
        exclusiveGateway: {
          name: 'Router',
          description: 'Verschiedene Wege gehen'
        },
        sendEmail: {
          name: 'E-Mail',
          description: 'Eine Mail senden'
        },
        sendHttpRequest: {
          name: 'HTTP-Anfrage',
          description: 'Eine beliebige HTTP-Anfrage senden'
        },
        qrCode: {
          name: 'QR Code',
          description: 'Erstellt einen QR Code'
        },
        requestTrigger: {
          name: 'Webhook',
          description: 'Ausgelöst durch eine externe Anfrage',
          configuration: {
            title: 'Link zum Starten des Flows',
            description: 'Um den Flow zu starten, senden Sie eine POST-Request an die folgende URL:',
            postRequestTitle: 'POST-Request senden',
            postRequest: 'Senden Sie Daten im JSON-Format per POST-Request an den obigen Link, um den Flow zu starten.',
            webhookTitle: 'Als Webhook verwenden',
            webhook: 'Tragen Sie die URL als Webhook bei anderen Services ein. Dadurch wird der Workflow automatisch gestartet, wenn der entsprechende Service den Webhook auslöst.',
            testWebhookTitle: 'Webhook testen',
            testWebhookDescription: 'Sie können den Webhook testen, indem Sie einen Beispiel-JSON-Payload senden:',
            jsonPayloadTitle: 'JSON-Payload',
            sendTestRequestButton: 'Jetzt testen',
            redirectDescription: 'Verwende den unten stehenden Link, um die erste Seite unseres Flows zu öffnen. Sobald diese Seite abgesendet wurde, wird der Flow gestartet.',
            redirectTips: 'Du kannst diesen Link auch weitergeben, zum Beispiel:\n\n- Als QR-Code für einfachen Zugriff\n- Auf Ihrer Webseite oder im Intranet verlinken\n\nTeile den Link mit Anderen, um den Workflow schnell und effizient zu starten.'          
          }
        },
        updateEntity: {
          name: 'Eintrag aktualisieren',
          description: 'Einen Eintrag mit neuen Werten aktualisieren'
        },
        editLink: {
          name: 'Vorausgefüllter Formularlink',
          description: 'Aktualisiert einen Eintrag',
        },
        addEntity: {
          name: 'Eintrag erstellen',
          description: 'Erstellt einen neuen Eintrag'
        },
        flowForm: {
          name: 'Seite anzeigen',
          description: 'Zeigt eine Seite zur Nutzerinteraktion an'
        },
        parallel: {
          name: 'Parallel',
          description: 'Führt nachfolgende Schritte für jedes Listenelement einzeln aus.'
        },
        pdf2text: {
          name: 'PDF zu Text',
          description: 'PDF in Text umwandeln'
        },
        speechToText: {
          name: 'Sprache zu Text',
          description: 'Audio in Text umwandeln'
        },
        listViewEntities: {
          name: 'Alle Einträge laden',
          description: 'Lade alle Einträge einer Grid Ansicht'
        },
        flowInstanceLink: {
          name: 'Link zu Seite erstellen',
          description: 'Erstellt einen Link zu einer Seite im Flow'
        },
        flowFormPageTrigger: {
          name: 'Seite anzeigen',
          description: 'Flow starten und Seite laden'
        }
      },
      nodeProperties: {
        pdf2text: {
          pdf_url: 'PDF-Datei-URL',
          returnArray: 'Text pro Seite extrahieren'
        },
        openAICompletion: {
          user: 'Prompt',
          system: 'Zusätzliche Anweisungen'
        },
        sendEmail: {
          from: 'Absender Name',
          html: 'HTML im Inhalt verwenden',
          replyTo: 'Antwort an',
          recipients: 'An',
          subject: 'Betreff',
          body: 'Inhalt',
          attachments: 'Anhänge'
        },
        sendHttpRequest: {
          url: 'URL',
          requestContent: 'Request Content',
          method: 'Methode',
          contentType: 'Content Type',
          query: 'Query',
          headers: 'Headers',
          body: 'Inhalt',
          username: 'Benutzername',
          password: 'Password',
        },
        gridPicker: 'Bitte wählen Sie ein Grid',
        formPicker: 'Bitte wählen Sie ein Formular',
        flowFormPicker: 'Bitte wählen Sie eine Seite',
        viewPicker: 'Bitte wählen Sie eine Ansicht',
        flowForm: {
          form: 'Button zum Absenden anzeigen',
          buttonLabel: 'Button Text',
        }
      },
      variablePicker: {
        title: 'Werte aus vorherigen Schritten',
        headerEmpty: 'Zuerst musst du den Flow testen',
        descriptionEmpty: 'Nach dem Testlauf hast Sie Zugriff auf Werte, die du dann in Feldern verwenden kannst.',
        flowVariables: 'Flow Variablen',
        search: 'Suchen',
        expressionsTitle: 'Ausdrücke',
      },
      condition: {
        title: 'Nur fortfahren, wenn ...',
        leftOperandLabel: 'Wähle Wert ...',
        operatorLabel: 'Wähle Bedingung ...',
        rightOperandLabel: 'Text eingeben ...',
        saveButton: 'Speichern',
        clearButton: 'Zurücksetzen',
        expression: 'Filter anzeigen',
        editConditionButton: 'Filter bearbeiten',
        deleteConditionButton: 'Verbindung löschen',
        editConditionLabelButton: 'Verbindung umbenennen',
        editConditionLabel: 'Verbindung umbenennen',
        unrecognizedExpression: 'Nicht erkannter Ausdruck'
      },
      templateKeyValues: {
        name: 'Key',
        value: 'Wert'
      },
      templateFilePicker: {
        fileName: 'Dateiname',
        fileType: 'Dateityp',
        fileData: 'Inhalt'
      },
      instanceDetail: {
        output: 'Output'
      },
      nodePicker: {
        featureRequest: 'Nichts passendes gefunden?',
        featureRequestAction: 'Stell deine Anfrage',
        triggerHeader: 'Auslöser',
        actionsHeader: 'Aktionen',
        helperHeader: 'Helfer',
        whenShouldFlowStart: 'Wann soll der Flow starten?',
        hasStartNode: 'Der Flow hat bereits einen Auslöser',
        actionsDescription: 'Wähle eine Aktion aus, um deinen Flow zu erweitern'
      },
      flowFormPicker: {
        addPage: 'Seite hinzufügen',
        editPage: 'Seite bearbeiten'
      }
    },
    operators: {
      isEqualTo: 'ist gleich',
      isNotEqualTo: 'ist nicht gleich',
      greaterThan: 'ist größer als',
      smallerThan: 'ist kleiner als',
      smallerThanOrEqual: 'ist kleiner als oder gleich',
      greaterThanOrEqual: 'ist größer als oder gleich',
      endsWith: 'endet mit',
      contains: 'enthält',
      beginsWith: 'beginnt mit',
      after: 'ist nach',
      before: 'ist vor',
      isEmpty: 'ist leer',
      isNotEmpty: 'ist nicht leer',
      isTrue: 'ist wahr',
      isFalse: 'ist falsch',
      logicalAnd: 'und',
      logicalOr: 'oder'
    },
    paywall: {
      upgradeNow: 'Jetzt upgraden',
      upgradeNowLong: 'Upgraden, um diese Funktion freizuschalten',
      upgradeTeaserTitle: 'Upgraden, um mehr Funktionen freizuschalten',
      upgradeTeaserSubtitle: 'Sie nutzen derzeit unseren {planName} Plan. Wenn Sie erweiterte Funktionen nutzen möchten, upgaden Sie jetzt auf unseren Plus oder Pro Plan.',
      upgradeTeaserChatButton: 'Chatten Sie mit uns',
      upgradeSeats: 'Nutzer anpassen',
      myPlan: 'Ihr Plan',
      trial: {
        title: 'Testphase',
        daysRemaining: '{days} Tage verbleibend in der Testphase'
      },
      seatsConsumed: {
        title: 'Überschreitung der lizenzierten Plätze in Ihrem Tarif',
        moreUsersInvited: 'Es scheint, als hätten Sie mehr Benutzer eingeladen, als Sie lizenzierte Plätze für NutzerInnen haben.',
        needAdditionalPlaces: 'Sie benötigen {numberOfNeededSeats} weitere Plätze.',
        needAdditionalPlace: 'Sie benötigen einen weiteren Platz.',
        changeUserPermissions: 'Ändern Sie die Berechtigungen von Benutzern auf "Nur Lesen" oder buchen Sie weitere Plätze.',
      },
      proNodes: {
        shortHint: 'Pro Nodes',
        longHint: 'Upgraden Sie auf Pro, um erweiterte Nodes wie OpenAI, PDF zu Text und mehr zu nutzen'
      },
      webhooksPerSpace: {
        shortHint: 'Weitere Webhooks anlegen',
        longHint: 'Upgrade um weitere Webhooks anzulegen'
      },
      flows: {
        shortHint: 'Weitere Flows anlegen',
        longHint: 'Upgrade um weiter Flows anzulegen und mehr ausführen zu können.'
      },
      saveFormProgress: {
        shortHint: 'Speichern um später fortzufahren',
        longHint: ''  
      },
      formSuccessRedirect: {
        shortHint: 'Auf eine Webseite weiterleiten',
        longHint: ''  
      },
      formHideAd: {
        shortHint: 'Produkt Hinweis ausblenden',
        longHint: ''  
      },
      writersPerSpace: {
        shortHint: 'Weitere Nutzer zum Bearbeiten einladen',
        longHint: ''
      },
      entitiesPerGrid: {
        shortHint: 'Weitere Einträge hinzufügen',
        longHint: 'Wechseln Sie in einen höheren Plan um weitere Einträge hinzuzufügen'
      },
      formReadOnlyFields: {
        shortHint: 'Felder die nicht veränderbar sind',
      },
      formDefaultValues: {
        shortHint: 'Vorausgefüllte Felder',
      },
      formHiddenFields: {
        shortHint: 'Felder die nicht sichtbar sind',
      },
      autoSubmitForms: {
        shortHint: 'Automatisch einsenden',
      },
      downloadQRCodeSVG: {
        shortHint: 'SVG-Datei erstellen'
      },
      upgardeBanner: {
        longHint: 'Möchtest Du das Beste aus unserer Plattform herausholen? '
      },
      formsPerSpace: {
        shortHint: 'Weitere Formulare hinzufügen',
      },
      logoOnForms: {
        shortHint: 'Füge Dein Logo hinzu',
      },
      spaceColors: {
        shortHint: 'Individuelle Space-Farben',
      },
      addExternalLinks: {
        shortHint: 'Mehrere externe Links hinzufügen und verwalten',
      },
      signature: {
        title: 'Unterschrift',
        subtitle: 'Unterschreiben Sie Ihren Namen'
      },
    },
    spaceInvitation: {
      inviteText: 'Sie wurden zu einem Space eingeladen!',
      inviteAcceptButtonText: 'Einladung annehmen',
      errorWrongEmail: 'Die Einladung kann nur mit der zugehörigen E-Mail-Adresse angenommen werden.',
      error: 'Die Einladung konnte nicht angenommen werden.'
    },    
    helpMenu: {
      title: 'Hilfe & Support',
      helpCenter: 'Hilfe-Center',
      chatWithUs: 'Chatte mit uns',
      feedback: 'Senden Sie uns Feedback'
    },
    iconPicker: {
      removeButton: 'Zurücksetzen',
      searchIcons: '\u{1F50E} Icon Suche',
      uploadFileIcon: 'Icon-Datei hochladen',
      changeFileIcon: 'Icon-Datei ändern',
      removeFileIcon: 'Icon-Datei entfernen',
      uploadFileIconPrompt: 'Bild hier ablegen... oder <u>klicken zum Durchsuchen</u>'
    },
    entityUpdatedCondition: {
      hint: 'Wenn',
      fieldSelectPlaceholder: 'ein beliebiges Feld',
      fieldSelectLabel: 'geändert wurde',
      parameterPlaceholder: 'Beliebiger Wert',
      anyValue: 'Beliebiger Wert',
      noValue: 'Kein Wert',
      from: 'von',
      to: 'auf'
    },
    entityPicker: {
      addButton: '{fieldName} auswählen',
      filterPlaceholder: 'Suchen ...',
      createEntryButton: '{fieldName} erstellen',
      linkedEntries: 'Verknüpfte Einträge',
      searchResults: 'Suchergebnisse',
      loadMore: 'Mehr laden... ({items} / {totalItems})',
      empty: 'Kein Eintrag',
      error: 'Sie haben leider keine Berechtigung, diesen Eintrag anzuzeigen.'
    },
    editableTexPlaceholder: 'Tippen Sie hier ...',
    errorReport: {
      title: 'Fehlermeldung',
      subtitle: 'Es tut uns leid, aber ein Fehler ist aufgetreten. Bitte entschuldigen Sie die Unannehmlichkeiten. Nachfolgend finden Sie die Details des Fehlers.',
      addReportButton: 'Anmerkung hinzufügen',
      placeholder: '(optional) Beschreiben Sie, wann Fehler aufgetreten ist.'
    },
    entityCount: '{count} Einträge',
    lookupOptions: {
      referenceLabel: 'Wählen Sie eine Referenzspalte aus',
      lookupLabel: 'Wählen Sie eine Spalte aus {gridName}'
    },
    sumupOptions: {
      referenceLabel: 'Wählen Sie eine Multireferenzspalte aus',
      sumupLabel: 'Wählen Sie eine Zahlenspalte aus {gridName}'
    },
    formulaOptions: {
      expressionLabel: 'Formel',
      valueTypeModel: 'Ergebnis anzeigen als',
      showAsToken: 'Formel als Token einblenden',
      showAsText: 'Formel mit Text einblenden',
      noSupportedField: 'Es ist kein Feld eines unterstützten Typs verfügbar. Unterstützte Typen : {types}'
    },
    onboarding: {
      purposeTitle : 'In welchem Bereich möchtest du ApptiveGrid einsetzen?',
      private: 'Privat',
      work: 'Arbeit',
      other: 'Sonstiges',
      welcome: 'Herzlich Willkommen'
    },
    addressPicker: {
      country: 'Land',
      line1: 'Adresse Zeile 1',
      line2: 'Adresse Zeile 2',
      city: 'Stadt',
      postCode: 'Postleitzahl',
      state: 'Bundesland'
    },
    login: {
      title: 'Willkommen!',
      subtitle: 'Geben Sie die Anmeldeinformationen ein.',
      username: 'Benutzername',
      password: 'Passwort',
      button: 'Anmelden',
      error: 'Bitte überprüfen Sie Benutzername und Passwort',
      rule: {
        username: 'Benutzername ist ein Pflichtfeld',
        password: 'Passwort ist ein Pflichtfeld'
      }
    },
    blockTypes: {
      page: {
        title: 'Seite',
        subtitle: 'Eine Unterseite hinzufügen'
      },
      paragraph: {
        title: 'Absatz',
        subtitle: 'Schreibe normalen Text', 
      },
      header1: {
       title: 'Überschrift 1',
       subtitle: 'Große Überschrift', 
      },
      header2: {
        title: 'Überschrift 2',
        subtitle: 'Mittlere Überschrift', 
      },
      header3: {
        title: 'Überschrift 3',
        subtitle: 'Kleine Überschrift', 
      },
      text: {
        title: 'Einzeiliges Textfeld',
        subtitle: 'Für kurze Eingaben wie Name, Firma oder Betreff.'
      },
      textArea: {
        title: 'Mehrzeiliges Textfeld',
        subtitle: 'Für längere Eingaben wie Kommentare oder Beschreibungen.'
      },
      grid: 'Grid',
      dateTime: 'Datum und Uhrzeit',
      dictionary: 'Wörterbuch',
      attachmentCollection: {
        title: 'Datei hochladen',
        subtitle: 'Eine oder mehrere Dateien hochladen'
      },
      uri: {
        title: 'URL',
        subtitle: 'Eine Webseite Link einfügen'
      },
      decimal: {
        title: 'Kommazahl',
        subtitle: 'Eine Zahl mit Nachkommastellen einfügen'
      },
      mimeEntityCollection: 'Mimeentitycollection',
      entity: {
        title: 'Eintrag',
        subtitle: 'Einen Eintrag aus einem Grid auswählen'
      },
      integer: {
        title: 'Zahl',
        subtitle: 'Eine ganze Zahl einfügen'
      },
      mimeEntity: 'Mimeentity',
      enumCollection: {
        title: 'Mehrfachauswahl',
        subtitle: 'Mehrere Werte aus einer Liste auswählen'
      },
      user: 'Benutzer',
      Standort: 'Standort',
      boolean: 'Häkchen',
      currency: 'Währung',
      date: 'Datum',
      multiText: 'Multitext',
      address: 'Anschrift',
      enum: {
        title: 'Auswahl',
        subtitle: 'Einen Wert aus einer Liste auswählen'
      },
      entityCollection: {
        title: 'Einträge',
        subtitle: 'Mehrere Einträge aus einem Grid auswählen'
      },
      signature: {
        title: 'Unterschrift',
        subtitle: 'Unterschrift einfügen'
      },
      email: {
        title: 'E-Mail',
        subtitle: 'Eine E-Mail Adresse einfügen'
      },
      phoneNumber: 'Telefonnummer',
      attachment: 'Anhang',
      Ressource: 'Ressource',
      block: 'Block',
      resourceBlock: {
        title: 'Grid Ansicht',
        subtitle: 'Eine Ansicht einbinden, z.B. Kanban, Grid, Kalender, Karte oder Galerie',
      },
      externalHookButtonBlock: {
        title: 'Button',
        subtitle: 'Button um ein Formular oder einen Flow zu starten',
      },
      embed: {
        title: 'Einbinden',
        subtitle: 'Zeige beliebige externe Inhalte an, z. B. Webseiten oder Videos.',
      },
      video: {
        title: 'Video',
        subtitle: 'Fügen Sie ein obligatorisches Video zu Ihrem Formular hinzu',
      },
      image: {
        title: 'Bild',
        subtitle: 'Fügen Sie ein Bild mit benutzerdefinierten Abmessungen hinzu',
      },
      voiceRecorder: {
        title: 'Sprachaufnahme',
        subtitle: 'Nehmen Sie eine Sprachaufnahme auf',
      },
      gridCountCardBlock: {
        title: 'Zähler',
        subtitle: 'Zähle die Anzahl der Einträge in einer Ansicht',
      },
      horizontalLayout: {
        title: 'Horizontales Layout',
        subtitle: 'Blocks nebeneinander anordnen',
      },
      html: {
        title: 'HTML',
        subtitle: 'Benutzerdefinierter HTML-Inhalt mit Werten aus einem Grid',
      },
      entityList: {
        title: 'Grid Liste',
        subtitle: 'Erstelle eine Liste basierend auf Einträgen aus einem Grid',
      },
    },
    blocks: {
      emptyParagraph: 'Leerer Absatz...',
      emptyHeader: 'Leere Überschrift...',
      headerLevel: 'Textgröße',
      textAlignment: 'Textausrichtung',
      textAlignments: {
        left: 'Linksbündig',
        center: 'Zentriert',
        right: 'Rechtsbündig',
        justify: 'Blocksatz'
      },
      backgroundColor: 'Hintergrundfarbe',
      textColor: 'Textfarbe',
      emptyPageTitle: 'Unbenannt',
      pages: 'Seiten',
      basicHeader: 'Basis Blocks',
      formFieldHeader: 'Formularfeld Blocks',
      specialHeader: 'Spezielle Blocks',
      pickerSearchPlaceholder: 'Schreibe zum Filtern...',
      pickerPlaceholder: 'Scheibe etwas oder drücke die Leertaste um einen Block hinzuzufügen',
      setHeaderImage: 'Cover Bild hinzufügen',
      headerImage: 'Bild-URL einfügen',
      setIcon: 'Icon und Farbe festlegen',
      deletePage: 'Seite löschen',
      deleteBlock: 'Block löschen',
      submitPage: 'Weiter',
      blockSettings: 'Einstellungen',
      renamePage: 'Seite umbenennen',
      renamePageTitle: '<p><span class="primary--text">{gridTitle}</span>&nbsp;umbenennen</p>',
      headers: {
        h1: 'Große Überschrift',
        h2: 'Mittlere Überschrift',
        h3: 'Kleine Überschrift'
      },
      emptyStateBlocksHeader: 'Willkommen in deinem Bereich "Seiten"',
      emptyStateBlocksDescription: 'Hier kannst du all deine Informationen, Notizen und Dokumentationen festhalten. Klicke auf "Seite erstellen" und halte alles fest, was dir wichtig ist!',
      emptyStateBlocksAddPage: 'Seite erstellen',
      addPage: 'Seite erstellen',
      resourceBlock: {
        menuTitle: 'Legen Sie die Grid Ansicht fest',
        inputLabel: 'Grid Ansicht',
        menuButton: 'Ansicht auswählen',
        switchToFullsize: 'Volle Breite anzeigen'
      },
      externalHookButtonBlock: {
        menuTitle: 'Legen Sie einen Link fest',
        inputLabel: 'Link',
        menuButton: 'Link auswählen',
        backgroundColor: 'Background Color',
        textColor: 'Text Color'
      },
      embed: {
        menuTitle: 'Legen Sie einen Link fest',
        uriInputLabel: 'Link einbinden'
      },
      video: {
        menuTitle: 'Legen Sie die Video Url fest',
        inputLabel: 'Video Url'
      },
      image: {
        menuTitle: 'Bildeinstellungen',
        urlLabel: 'Bild URL',
        widthLabel: 'Breite (px/%)',
        heightLabel: 'Höhe (px/%)'
      },
      inputBlock: {
        required: 'Pflichtfeld',
        blockSettings: 'Feld Einstellungen',
        requiredFieldError: 'Dieses Feld ist ein Pflichtfeld',
        delete: 'Löschen',
        needToViewToEnd: 'Bitte schauen Sie das Video bis zum Ende an.',
        requiredVideo: 'Bis zum Ende anschauen',
        readonly: 'Nicht änderbar',
      },
      setKeys: 'Keys verwalten',
      shareDialog: {
        title: 'Teile diese Seite',
        subtitle: 'Teile diese Seite mit Anderen',
        buttons: {
          publish: 'Veröffentlichen',
          remove: 'Löschen',
          open: 'Öffnen',
          qrCode: 'QR-Code',
          copy: 'Kopieren',
          setRestrictions: 'Einschränkungen setzen',
        }
      },
      horizontalLayout: {
        inputLabel: 'Anordnung',
        menuButton: 'Anordnung auswählen',
        layoutDirection: 'Anordnung',
        horizontal: 'Nebeneinander',
        vertical: 'Untereinander',
        mainAxisAlignment: 'Horizontal',
        counterAxisAlignment: 'Vertical',
        alignStart: 'Anfang',
        alignCenter: 'Zentriert',
        alignEnd: 'Ende',
        alignMin: 'Oben',
        alignMax: 'Unten',
        spaceBetween: 'Abstand dazwischen',
        stretch: 'Strecken',
      },
      switchType: 'Umwandeln in',
      htmlBlock: {
        editHtml: 'HTML bearbeiten',
        content: 'HTML Code',
        preview: 'Live Vorschau',
        templateHelp: 'Verwenden Sie {{ }} für Template-Variablen. Beispiel: {{variableName}}',
        description: 'Beschreiben Sie die Änderungen (z.B. "Einen Footer hinzufügen" oder "Den Karten-Container responsiv gestalten")',
      },
      entityList: {
        selectGrid: 'Grid auswählen',
        selectGridDescription: 'Wähle ein Grid aus, um automatisch eine Liste mit Einträgen zu erstellen.',
        selectItemBlock: 'Listeneinträge bearbeiten',
        gridEntryPlaceholder: 'Grid Eintrag Platzhalter',
      },
      setViewType: 'Seiten Layout festlegen',
      viewTypes: {
        default: 'Zentrierte Seite',
        full: 'Volle Breite',
      },
    },
    expressionEditor: {
      menuTitle: 'Einen Ausdruck bearbeiten',
      sampleResult: 'Beispielergebnis',
      typeDisplay: 'Typ: {type}',
      expressionLabel: 'Ausdruck'
    },
    resourceTypes: {
      grid: 'Grids und Ansichten',
      persistentGrid: 'Grids',
      view: 'Ansichten',
      gridView: 'Grid Ansichten',
      'virtual grid': 'Ansichten',
      space: 'Spaces',
      form: 'Formulare',
      block: 'Seiten',
      page: 'Seiten',
      spreadsheet: 'Spreadsheet Views',
      kanban: 'Kanban Views',
      calendar: 'Calendar Views',
      map: 'Map Views',
      gallery: 'Gallery Views',
      externalHook: 'Links',
      undefined: 'Sonstiges',
    },
    proofread: {
      undo: {
        tooltip: 'Vorherige Version'
      },
      friendly: {
        tooltip: 'Text freundlicher gestalten'
      },
      professional: {
        tooltip: 'Text professioneller gestalten'
      },
      rewrite: {
        tooltip: 'Text umformulieren'
      },
      proofread: {
        tooltip: 'Text korrigieren'
      },
    },
    voiceRecorder: {
      start: 'START',
      pause: 'PAUSE',
      continue: 'WEITER',
      finish: 'Fertig',
      allowMicrophone: 'Bitte erlauben Sie den Mikrofonzugriff für die Audioaufnahme',
      loading: 'Lade Sprachaufnahme...'
    },
    codeMirror: {
      shortcutHint: {
        prefix: 'Drücken Sie',
        ctrlKey: 'Strg',
        spaceKey: 'Leertaste',
        suffix: 'um Grid-Felder einzufügen'
      }
    },
    icon: {
      fileIcon: 'Icon-Datei'
    },
  },
}

// Create VueI18n instance with options
export default new VueI18n({
  locale: navigator.language?.includes('de') ? 'de' : 'en', // set locale
  messages // set locale messages
})
