import HalObject from '@/store/models/HalObject.js'
import store from '@/store/store'

export default class Block extends HalObject {
  constructor(data) {
    super(data)
    this.initFromData(data)
    this.isDirty = false
    this.deleted = false
  }

  initFromData(data) {
    this.type = data.type
    this[this.type] = data[this.type]
    this.block = data.block
    this.actions = data.actions
    this.has_children = data.has_children
    this.blockType = data.blockType
    this.metaType = data.metaType
    if (!this[this.type].annotations) {
      this[this.type].annotations = {}
    }
  }

  validate() {
    return true
  }

  get id() {
    return this.getLink('self').split('/').pop()
  }

  async reload() {
    const block = await store().dispatch('AGReadBlock',{ blockUri:  this.getLink('self')})
    for (let property in block) {
      this[property] = block[property]
    }
  }

  addChildAtPosition(position, newBlock) {
    if (position >= 0 && position <= this.children.length) {
      // Insert the new block at the specific position
      this.children.splice(position, 0, newBlock)
    } else {
      // If the position is out of bounds, append the block at the end
      this.children.push(newBlock)
    }
  }

  async deleteBlockIn(parentBlockOrSpace) {
      await store().dispatch('AGDeleteBlock', { block: this, parent: parentBlockOrSpace})
  }

  childRemoved(child) {
    const index = this.children.indexOf(child)
    if (index !== -1) {
      this.children.splice(index, 1)
    }
  }

  get hasChildren() {
    return this.children?.length > 0
  }

  getSerializableData() {
    return this.deepCopyWithoutExcludedProperties(this)
  }

  payload() {
    const payload = this.addFormPayload({})
    return payload
  }

  getExcludedProperties() {
    return ['parent']
  }

  deepCopyWithoutExcludedProperties(obj) {
    if (typeof obj !== 'object' || obj === null) return obj
    
    // If the object is a Block, use its own deep copy method
    if (obj instanceof Block && obj !== this) {
      return obj.deepCopyWithoutExcludedProperties(obj)
    }

    const copy = Array.isArray(obj) ? [] : {}
    const excludedProps = this.getExcludedProperties()

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && !excludedProps.includes(key)) {
        const value = obj[key]
        copy[key] = this.deepCopyWithoutExcludedProperties(value)
      }
    }
    return copy
  }

  addFormPayload(payload) {
    if (this.submitFormKey !== undefined) {
      const valueKey = this[this.type].type.name
      const value = this[this.type][valueKey]
      payload[this.submitFormKey] = value
    }
    if (this.has_children && this.metaType !== 'nestedFormInput') {
      this.children.forEach(block => {
        block.addFormPayload(payload)
      })
    }
    return payload
  }

  get submitFormKey() {
    return this.formKey
  }

  get childCount() {
    return this.children?.length ?? 0
  }

  get hasBlockInputs() {
    return (this.children ?? []).some(child => child.hasBlockInputs)
  }

  // Common annotation getters and setters for all blocks
  get color() {
    return this[this.type].annotations.color
  }

  set color(newVal) {
    this[this.type].annotations.color = newVal
    this.updateAnnotations()
  }

  get backgroundColor() {
    return this[this.type].annotations.backgroundColor
  }

  set backgroundColor(newVal) {
    this[this.type].annotations.backgroundColor = newVal
    this.updateAnnotations()
  }

  get fontFamily() {
    return this[this.type].annotations.fontFamily
  }

  set fontFamily(newVal) {
    this[this.type].annotations.fontFamily = newVal
    this.updateAnnotations()
  }

  get borderRadius() {
    return this[this.type].annotations.borderRadius
  }

  set borderRadius(newVal) {
    this[this.type].annotations.borderRadius = newVal
    this.updateAnnotations()
  }

  get padding() {
    return this[this.type].annotations.padding
  }

  set padding(newVal) {
    this[this.type].annotations.padding = newVal
    this.updateAnnotations()
  }

  async updateAnnotations() {
    await store().dispatch('AGPatchBlock', {
      block: this,
      payload: {
        [this.type]: {
          annotations: this[this.type].annotations
        }
      }
    })
  }
}