<template>
  <div>
    <SubMenu :activatorText="$t('blocks.textAlignment')" activatorIcon="mdi-format-align-left">
      <v-list-item
        v-for="align in textAlignments"
        :key="align.value"
        @click="setTextAlign(align.value)"
      >
        <div class="d-flex align-center">
          <v-icon
            :size="16"
            class="mr-3"
            :class="{ 'primary--text': textAlign === align.value }"
          >{{ align.icon }}</v-icon>
          <div>{{ align.label }}</div>
        </div>
      </v-list-item>
    </SubMenu>

    <v-list>
      <v-list-item>
        <v-list-item-content class="accent--text">
          <div class="d-flex align-center">
            <v-icon small class="mr-3">mdi-format-color-text</v-icon>
            <v-list-item-title>{{ $t('blocks.textColor') }}</v-list-item-title>
            <v-spacer></v-spacer>
            <v-menu
              v-model="showTextColorPicker"
              :close-on-content-click="false"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <div 
                  class="ml-2 rounded elevation-2" 
                  v-bind="attrs"
                  v-on="on"
                  :style="{
                    width: '22px !important',
                    height: '22px !important',
                    backgroundColor: color,
                    cursor: 'pointer'
                  }"
                ></div>
              </template>
              <v-card>
                <v-color-picker
                  v-model="color"
                  mode="hexa"
                  show-swatches
                ></v-color-picker>
              </v-card>
            </v-menu>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import SubMenu from '@/components/SubMenu.vue'
import { debounce } from 'lodash'
export default {
  name: 'TextFormatMenu',
  components: { SubMenu },
  props: ['block'],

  data() {
    return {
      showTextColorPicker: false,
      textAlignments: [
        { value: 'left', label: this.$t('blocks.textAlignments.left'), icon: 'mdi-format-align-left' },
        { value: 'center', label: this.$t('blocks.textAlignments.center'), icon: 'mdi-format-align-center' },
        { value: 'right', label: this.$t('blocks.textAlignments.right'), icon: 'mdi-format-align-right' },
      ],
      localTextColor: null
    }
  },
  created() {
    this.debouncedUpdateTextColor = debounce(this.updateTextColor, 300)
  },
  computed: {
    textAlign: {
      get() {
        return this.block.textAlign || 'left'
      },
      set(newVal) {
        this.block.textAlign = newVal
      }
    },
    color: {
      get() {
        return this.localTextColor ?? this.block.color ?? '#000000'
      },
      set(newVal) {
        this.localTextColor = newVal
        this.debouncedUpdateTextColor(newVal)
      }
    }
  },

  methods: {
    setTextAlign(align) {
      this.textAlign = align
    },
    updateTextColor(newVal) {
      this.block.color = newVal
    }
  }
} 

</script> 