import i18n from '@/plugins/i18n'

export default {
  datevLodas: {
    typeOverride: 'datevLodas',
    overrideHttp: true,
    type: 'sendHttpRequest',
    typeName: 'DATEV Lohn & Gehalt',
    description: 'Erstellt eine DATEV Import-Datei',
    icon: 'mdi-account-multiple-check-outline',
    graphIcon: 'mdi-account-multiple-check-outline',
    color: '#7ebb25',
    proNode: true,
    mapProperties: (props) => {
      return { 
        url: 'https://twf2muqecd.execute-api.eu-central-1.amazonaws.com/file',
        requestContent: JSON.stringify({
          accessKey: props.accessKey,
          password: props.password,
          entityUri: props.entityUri
        }),
        method: 'POST',
        contentType: 'application/json',
      }
    },
    propertiesOverride: (props) => {
      let requestContent = JSON.parse(props?.requestContent || '{}')
      return {
        entityUri: requestContent.entityUri || null,
        accessKey: requestContent.accessKey || null,
        password: requestContent.password || null
      }
    },
    propertiesSchemaOverride: [
      {
        'schema': {
            'type': 'string'
        },
        'name': 'entityUri',
        'componentType': 'textfield',
        'type': 'string'
    },
    {
      'schema': {
          'type': 'string'
      },
      'name': 'accessKey',
      'componentType': 'textfield',
      'type': 'string'
    },
    {
      'schema': {
          'type': 'string'
      },
      'name': 'password',
      'componentType': 'textfield',
      'type': 'string'
    },
    ]
  },
  slack: {
    typeOverride: 'slack',
    overrideHttp: true,
    docuLink: 'https://intercom.help/apptivegrid/de/articles/8237837-slack-nachrichten', 
    type: 'sendHttpRequest',
    typeName: 'Slack',
    description: 'Send Message',
    icon: 'mdi-slack',
    graphIcon: 'mdi-slack',
    color: '#3F0E40',
    proNode: false,
    mapProperties: (props) => {
      return { 
        url: props.webhookUrl,
        requestContent: JSON.stringify({
          text: props.text,
        }),
        method: 'POST',
        contentType: 'application/json',
      }
    },
    propertiesOverride: (props) => {
      let requestContent = JSON.parse(props?.requestContent || '{}')
      return {
        text: requestContent.text || null,
        webhookUrl: props?.url || null
      }
    },
    propertiesSchemaOverride: [
      {
        'schema': {
            'type': 'string'
        },
        'name': 'text',
        'componentType': 'textarea',
        'type': 'string'
      },
      {
        'schema': {
            'type': 'string'
        },
        'name': 'webhookUrl',
        'componentType': 'textfield',
        'type': 'string'
      }
    ]
  },
  teamsMessageWebHook: {
    typeOverride: 'teamsMessageWebHook',
    overrideHttp: true,
    docuLink: 'https://intercom.help/apptivegrid/de/articles/8453799-microsoft-teams-nachrichten', 
    type: 'sendHttpRequest',
    typeName: 'MS Teams Message',
    description: 'Send a Message',
    icon: 'mdi-microsoft-teams',
    graphIcon: 'mdi-microsoft-teams',
    color: '#505AC9',
    proNode: false,
    mapProperties: (props) => {
      return { 
        url: props.webhookUrl,
        requestContent: JSON.stringify({
          type: 'message',
          attachments: [
              {
                  contentType: 'application/vnd.microsoft.card.adaptive',
                  contentUrl: null,
                  content: {
                      $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
                      type: 'AdaptiveCard',
                      version: '1.5',
                      body: [
                          {
                              type: 'TextBlock',
                              text: props.text
                          }
                      ]
                  }
              }
          ]
        }),
        method: 'POST',
        contentType: 'application/json',
      }
    },
    propertiesOverride: (props) => {
      let requestContent = JSON.parse(props?.requestContent || null)
      return {
        text: requestContent ? requestContent.attachments[0].content?.body[0].text : null,
        webhookUrl: props?.url || null
      }
    },
    propertiesSchemaOverride: [
      {
        'schema': {
            'type': 'string'
        },
        'name': 'text',
        'componentType': 'textarea',
        'type': 'string'
      },
      {
        'schema': {
            'type': 'string'
        },
        'name': 'webhookUrl',
        'componentType': 'textfield',
        'type': 'string'
      }
    ]
  },
  firebasePush: {
    typeOverride: 'firebasePush',
    overrideHttp: true,
    docuLink: 'https://intercom.help/apptivegrid/de/articles/8250496-firebase-cloud-message', 
    type: 'sendHttpRequest',
    typeName: 'Firebase Cloud Messaging',
    description: 'Send a message',
    icon: 'mdi-firebase',
    graphIcon: 'mdi-firebase',
    color: '#FFA611',
    proNode: false,
    mapProperties: (props) => {
      return { 
        url: 'https://fcm.googleapis.com/fcm/send',
        requestContent: JSON.stringify({
          to: props.topic?.startsWith('/topics/') ? props.topic : props.topic ? `/topics/${props.topic}` : null,
          registration_ids: props.deviceTokens,
          notification: { 
            title: props.title,
            body: props.body
          },
          data: {
            ...props.data
          }
        }),
        headers: {
          Authorization: props.APIkey?.startsWith('key=') ? props.APIkey : `key=${props.APIkey}` 
        },
        method: 'POST',
        contentType: 'application/json',
      }
    },
    propertiesOverride: (props) => {
      let requestContent = JSON.parse(props?.requestContent || '{}')
      return {
        topic: requestContent.to?.replace('/topics/', '') || null,
        deviceTokens: requestContent.registration_ids || null,
        title: requestContent.notification?.title || null,
        body: requestContent.notification?.body || null,
        data: requestContent.data || null,
        APIkey: props?.headers?.Authorization?.replace('key=', '')  || null ,
      }
    },
    propertiesSchemaOverride: [
      {
        'schema': {
            'type': 'string'
        },
        'name': 'topic',
        'componentType': 'textfield',
        'type': 'string'
      },
      {
        'schema': {
            'type': 'string'
        },
        'name': 'deviceTokens',
        'componentType': 'multitextfield',
        'type': 'object'
      },
      {
        'schema': {
            'type': 'string'
        },
        'name': 'title',
        'componentType': 'textarea',
        'type': 'string'
      },
      {
        'schema': {
            'type': 'string'
        },
        'name': 'body',
        'componentType': 'textarea',
        'type': 'string'
      },
      {
        'schema': {
            'type': 'any'
        },
        'name': 'data',
        'componentType': 'multikeyvalue',
        'type': 'dictionary'
      },
      {
        'schema': {
            'type': 'string'
        },
        'name': 'APIkey',
        'componentType': 'textfield',
        'type': 'string'
      },
    ]
  },
  qrCode: {
    typeOverride: 'qrCode',
    overrideHttp: true,
    type: 'sendHttpRequest',
    typeName: i18n.t('flow.nodes.qrCode.name'),
    description: i18n.t('flow.nodes.qrCode.description'),
    icon: 'mdi-qrcode',
    graphIcon: 'mdi-qrcode',
    color: '#000000',
    proNode: false,
    mapProperties: (props) => {
      return { 
        url: 'https://km2z41erh4.execute-api.eu-central-1.amazonaws.com/generateCode',
        requestContent: JSON.stringify({
          data: props.text,
        }),
        method: 'POST',
        contentType: 'application/json',
      }
    },
    propertiesOverride: (props) => {
      let requestContent = JSON.parse(props?.requestContent || '{}')
      return {
        text: requestContent.data || null,
      }
    },
    propertiesSchemaOverride: [
      {
        'schema': {
            'type': 'string'
        },
        'name': 'text',
        'componentType': 'textfield',
        'type': 'string'
     },
    ]
  },
  openAICompletion: {
    typeOverride: 'openAICompletion',
    overrideHttp: true,
    type: 'sendHttpRequest',
    typeName: 'OpenAI',
    description: 'ChatGPT',
    icon: 'mdi-chat',
    graphIcon: 'mdi-chat',
    image: 'nodes/openAI.svg',
    color: '#74AA9C',
    proNode: true,
    mapProperties: (props) => {
      // Create the initial messages array with the user message
      let messages = [
        {
          role: 'user',
          content: props.user,
        },
      ]
    
      // Conditionally add the system message if props.system is not null
      if (props.system) {
        messages.unshift({
          role: 'system',
          content: props.system,
        })
      }
    
      return { 
        url: 'https://irpxjumiq7.execute-api.eu-central-1.amazonaws.com/completion',
        requestContent: JSON.stringify({
          model: props.model || 'gpt-4',
          messages: messages
        }),
        method: 'POST',
        contentType: 'application/json',  
      }
    },
    propertiesOverride: (props) => {
      let requestContent = JSON.parse(props?.requestContent || '{}')
      let messages = requestContent.messages || []
      return {
        system: messages.find(message => message.role === 'system')?.content || null,
        user: messages.find(message => message.role === 'user')?.content || null,
        model: requestContent.model 
      }
    },
    propertiesSchemaOverride: [
      {
        'schema': {
            'type': 'string',
            'options': [
              'gpt-4o',
              'gpt-4-turbo',
              'gpt-3.5-turbo-0125'
            ],
        },
        'name': 'model',
        'componentType': 'selectBox',
        'type': 'enum'
     },
     {
        'schema': {
            'type': 'string'
        },
        'name': 'user',
        'componentType': 'textfield',
        'type': 'string'
     },
     {
      'schema': {
          'type': 'string'
      },
      'name': 'system',
      'componentType': 'textfield',
      'type': 'string'
      },
    ]
  },
  calendarEvent: {
    typeOverride: 'calendarEvent',
    overrideHttp: true,
    docuLink: 'https://intercom.help/apptivegrid/de/articles/8355793-calendar-event', 
    type: 'sendHttpRequest',
    typeName: 'Calendar Event',
    description: 'Creates calendar event',
    icon: 'mdi-calendar',
    graphIcon: 'mdi-calendar',
    color: '#5482EB',
    mapProperties: (props) => {
      return { 
        url: 'https://ahxhj4dlw4.execute-api.eu-central-1.amazonaws.com/event',
        requestContent: JSON.stringify({
          start: props.start,
          end: props.end,
          title: props.title,
          description: props.description,
          location: props.location,
          attendees: [ 
            ...props.attendees?.map(attendee => ({ email: attendee })) || null
          ],
          organizer: {
            email: props.organizer
          },
          uid: props.identifier,
          sequence: props.version
        }),
        headers: {
        },
        method: 'POST',
        contentType: 'application/json',
      }
    },
    propertiesOverride: (props) => {
      let requestContent = JSON.parse(props?.requestContent || '{}')
      return {
        start: requestContent.start || null,
        end: requestContent.end || null,
        title: requestContent.title || null,
        description: requestContent.description || null,
        location: requestContent.location || null,
        attendees: requestContent.attendees?.map( attendee => attendee.email ) || null,
        organizer: requestContent.organizer?.email || null,
        identifier: requestContent.uid || null,
        version: requestContent.sequence || null,
      }
    },
    propertiesSchemaOverride: [
      {
        'schema': {
            'type': 'string'
        },
        'name': 'start',
        'componentType': 'textfield',
        'type': 'string'
      },
      {
        'schema': {
            'type': 'string'
        },
        'name': 'end',
        'componentType': 'textfield',
        'type': 'string'
      },
      {
        'schema': {
            'type': 'string'
        },
        'name': 'title',
        'componentType': 'textfield',
        'type': 'string'
      },
      {
        'schema': {
            'type': 'string'
        },
        'name': 'description',
        'componentType': 'textfield',
        'type': 'string'
      },
      {
        'schema': {
            'type': 'string'
        },
        'name': 'location',
        'componentType': 'textfield',
        'type': 'string'
      },
      {
        'schema': {
            'type': 'any'
        },
        'name': 'attendees',
        'componentType': 'multitextfield',
        'type': 'dictionary'
      },
      {
        'schema': {
            'type': 'any'
        },
        'name': 'organizer',
        'componentType': 'textfield',
        'type': 'dictionary'
      },
      {
        'schema': {
            'type': 'any'
        },
        'name': 'identifier',
        'componentType': 'textfield',
        'type': 'dictionary'
      },
      {
        'schema': {
            'type': 'string'
        },
        'name': 'version',
        'componentType': 'textfield',
        'type': 'dictionary'
      },
    ]
  },
  hubspotAddContact: {
    typeOverride: 'hubspotAddContact',
    overrideHttp: true,
    type: 'sendHttpRequest',
    typeName: 'Hubspot',
    description: 'Add Contact to Hubspot CRM',
    icon: 'mdi-hubspot',
    graphIcon: 'mdi-hubspot',
    color: '#ED8260',
    mapProperties: (props) => {
      return { 
        url: 'https://api.hubapi.com/crm/v3/objects/contacts',
        requestContent: JSON.stringify({
          properties: {
            firstname: props.firstname,
            lastname: props.lastname,
            email: props.email,
            phone: props.phone,
            website: props.website,
            company: props.company,
            address: props.address,
            city: props.city,
            state: props.state,
            zip: props.zip,
            country: props.country
          } 
        }),
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: props.APIkey?.startsWith('Bearer ') ? props.APIToken : `Bearer ${props.APIToken}` 
        },
      }
    },
    propertiesOverride: (props) => {
      let requestContent = JSON.parse( props?.requestContent || '{}')
      return {
        firstname: requestContent.properties?.firstname || null,
        lastname: requestContent.properties?.lastname || null,
        email: requestContent.properties?.email || null,
        phone: requestContent.properties?.phone || null,
        website: requestContent.properties?.website || null,
        company: requestContent.properties?.company || null,
        address: requestContent.properties?.address || null,
        city: requestContent.properties?.city || null,
        state: requestContent.properties?.state || null,
        zip: requestContent.properties?.zip || null,
        country: requestContent.properties?.country || null,
        APIToken: props?.headers?.Authorization?.replace('Bearer ', '')  || null ,
      }
    },
    propertiesSchemaOverride: [
      {
        'schema': {
            'type': 'string'
        },
        'name': 'APIToken',
        'componentType': 'textfield',
        'type': 'string'
      },
      {
        'schema': {
            'type': 'string'
        },
        'name': 'firstname',
        'componentType': 'textfield',
        'type': 'string'
     },
     {
      'schema': {
          'type': 'string'
      },
      'name': 'lastname',
      'componentType': 'textfield',
      'type': 'string'
    },
    {
      'schema': {
          'type': 'string'
      },
      'name': 'email',
      'componentType': 'textfield',
      'type': 'string'
    },     {
      'schema': {
          'type': 'string'
      },
      'name': 'phone',
      'componentType': 'textfield',
      'type': 'string'
    },     
    {
      'schema': {
          'type': 'string'
      },
      'name': 'website',
      'componentType': 'textfield',
      'type': 'string'
    },     
    {
      'schema': {
          'type': 'string'
      },
      'name': 'company',
      'componentType': 'textfield',
      'type': 'string'
    },     
    {
      'schema': {
          'type': 'string'
      },
      'name': 'address',
      'componentType': 'textfield',
      'type': 'string'
    },     
    {
      'schema': {
          'type': 'string'
      },
      'name': 'city',
      'componentType': 'textfield',
      'type': 'string'
    },     
    {
      'schema': {
          'type': 'string'
      },
      'name': 'state',
      'componentType': 'textfield',
      'type': 'string'
    },     
    {
      'schema': {
          'type': 'string'
      },
      'name': 'zip',
      'componentType': 'textfield',
      'type': 'string'
    },
    {
      'schema': {
          'type': 'string'
      },
      'name': 'country',
      'componentType': 'textfield',
      'type': 'string'
    },
    ]
  },
  pdf2text: {
    typeOverride: 'pdf2text',
    overrideHttp: true,
    type: 'sendHttpRequest',
    typeName: i18n.t('flow.nodes.pdf2text.name'),
    description: i18n.t('flow.nodes.pdf2text.description'),
    icon: 'mdi-file-pdf-box',
    graphIcon: 'mdi-file-pdf-box',
    color: '#FF453AFF',
    proNode: true,
    mapProperties: (props) => {
      return { 
        url: 'https://wd5ya4j80e.execute-api.eu-central-1.amazonaws.com/convert',
        requestContent: JSON.stringify({
          pdf_url: props.pdf_url,
          returnArray: props.returnArray
        }),
        method: 'POST',
        contentType: 'application/json',
      }
    },
    propertiesOverride: (props) => {
      let requestContent = JSON.parse(props?.requestContent || '{}')
      return {
        pdf_url: requestContent.pdf_url || null,
        returnArray: requestContent.returnArray || null,
      }
    },
    propertiesSchemaOverride: [
      {
        'schema': {
            'type': 'string'
        },
        'name': 'pdf_url',
        'componentType': 'textfield',
        'type': 'string'
     },
     {
      'schema': {
          'type': 'boolean'
      },
      'name': 'returnArray',
      'componentType': 'boolean',
      'type': 'boolean'
   },
    ]
  },
  magentaVerify: {
    typeOverride: 'magentaVerify',
    overrideHttp: true,
    type: 'sendHttpRequest',
    typeName: 'Magenta Business Verify',
    description: 'Send SMS verification',
    icon: 'mdi-account-lock-outline',
    graphIcon: 'mdi-account-lock-outline',
    color: '#E20074',
    proNode: true,
    mapProperties: (props) => {
      return {
        url: 'https://api.nexmo.com/v2/verify/',
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': props.authorization
        },
        requestContent: JSON.stringify({
          brand: props.brand,
          workflow: [
            {
              channel: 'sms',
              to: props.phoneNumber
            }
          ]
        })
      }
    },
    propertiesOverride: (props) => {
      let requestContent = JSON.parse(props?.requestContent || '{}')
      return {
        brand: requestContent.brand || 'ACME',
        phoneNumber: requestContent.workflow?.[0]?.to || null,
        authorization: props?.headers?.Authorization || null
      }
    },
    propertiesSchemaOverride: [
      {
        'schema': {
          'type': 'string'
        },
        'name': 'authorization',
        'componentType': 'textfield',
        'type': 'string'
      },
      {
        'schema': {
          'type': 'string'
        },
        'name': 'brand',
        'componentType': 'textfield',
        'type': 'string'
      },
      {
        'schema': {
          'type': 'string'
        },
        'name': 'phoneNumber',
        'componentType': 'textfield',
        'type': 'string'
      }
    ]
  },
  magentaVerifyCheck: {
    typeOverride: 'magentaVerifyCheck',
    overrideHttp: true,
    type: 'sendHttpRequest',
    typeName: 'Magenta Business Verify Check',
    description: 'Verify SMS code',
    icon: 'mdi-account-lock-outline',
    graphIcon: 'mdi-account-lock-outline',
    color: '#E20074',
    proNode: true,
    mapProperties: (props) => {
      return {
        url: `https://api.nexmo.com/v2/verify/${props.request_id}`,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': props.authorization
        },
        requestContent: JSON.stringify({
          code: props.code
        })
      }
    },
    propertiesOverride: (props) => {
      let requestContent = JSON.parse(props?.requestContent || '{}')
      return {
        request_id: props.url?.split('/').pop() || null,
        code: requestContent.code || null,
        authorization: props?.headers?.Authorization || null
      }
    },
    propertiesSchemaOverride: [
      {
        'schema': {
          'type': 'string'
        },
        'name': 'authorization',
        'componentType': 'textfield',
        'type': 'string'
      },
      {
        'schema': {
          'type': 'string'
        },
        'name': 'request_id',
        'componentType': 'textfield',
        'type': 'string'
      },
      {
        'schema': {
          'type': 'string'
        },
        'name': 'code',
        'componentType': 'textfield',
        'type': 'string'
      }
    ]
  },
  magentaSMS: {
    typeOverride: 'magentaSMS',
    overrideHttp: true,
    type: 'sendHttpRequest',
    typeName: 'Magenta Business SMS',
    description: 'Send SMS message',
    icon: 'mdi-message-text',
    graphIcon: 'mdi-message-text',
    color: '#E20074',
    proNode: true,
    mapProperties: (props) => {
      return {
        url: 'https://rest.nexmo.com/sms/json',
        method: 'POST',
        contentType: 'application/json',
        requestContent: JSON.stringify({
          api_key: props.api_key,
          api_secret: props.api_secret,
          from: props.from,
          to: props.to,
          text: props.text
        })
      }
    },
    propertiesOverride: (props) => {
      let requestContent = JSON.parse(props?.requestContent || '{}')
      return {
        api_key: requestContent.api_key || null,
        api_secret: requestContent.api_secret || null,
        from: requestContent.from || null,
        to: requestContent.to || null,
        text: requestContent.text || null
      }
    },
    propertiesSchemaOverride: [
      {
        'schema': {
          'type': 'string'
        },
        'name': 'api_key',
        'componentType': 'textfield',
        'type': 'string'
      },
      {
        'schema': {
          'type': 'string'
        },
        'name': 'api_secret',
        'componentType': 'textfield',
        'type': 'string'
      },
      {
        'schema': {
          'type': 'string'
        },
        'name': 'from',
        'componentType': 'textfield',
        'type': 'string'
      },
      {
        'schema': {
          'type': 'string'
        },
        'name': 'to',
        'componentType': 'textfield',
        'type': 'string'
      },
      {
        'schema': {
          'type': 'string'
        },
        'name': 'text',
        'componentType': 'textarea',
        'type': 'string'
      }
    ]
  },
  speechToText: {
    typeOverride: 'speechToText',
    overrideHttp: true,
    type: 'sendHttpRequest',
    typeName: i18n.t('flow.nodes.speechToText.name'),
    description: i18n.t('flow.nodes.speechToText.description'),
    icon: 'mdi-microphone-message',
    graphIcon: 'mdi-microphone-message',
    color: '#16C133',
    proNode: true,
    mapProperties: (props) => {
      return { 
        url: 'https://nmioc1607a.execute-api.eu-central-1.amazonaws.com/convert',
        requestContent: JSON.stringify({
          locale: props.locale || 'de-DE',
          audioUrl: props.audioUrl
        }),
        method: 'POST',
        contentType: 'application/json',
      }
    },
    propertiesOverride: (props) => {
      let requestContent = JSON.parse(props?.requestContent || '{}')
      return {
        locale: requestContent.locale || 'de-DE',
        audioUrl: requestContent.audioUrl || null,
      }
    },
    propertiesSchemaOverride: [
      {
        'schema': {
            'type': 'string',
            'options': [
              'de-DE',
              'en-US',
              'fr-FR',
              'es-ES',
              'it-IT'
            ],
        },
        'name': 'locale',
        'componentType': 'selectBox',
        'type': 'enum'
      },
      {
        'schema': {
            'type': 'string'
        },
        'name': 'audioUrl',
        'componentType': 'textfield',
        'type': 'string'
      }
    ]
  }
}