<template>
  <v-sheet class="d-flex flex-column parent-height">
    <v-toolbar flat dense class="flex-grow-0">
      <v-spacer />

    </v-toolbar>
    <v-tabs v-model="activeTab" grow class="flex-grow-0">
      <v-tab>{{ $t('flow.nodePicker.triggerHeader') }}</v-tab>
      <v-tab>{{ $t('flow.nodePicker.actionsHeader') }}</v-tab>
      <v-tab>{{ $t('flow.nodePicker.helperHeader') }}</v-tab>
 
      <v-tab-item class="tab-content">
        <div class="text-subtitle-1 font-weight-medium mt-4 accent-text text-center">
          {{ hasStartNode ? $t('flow.nodePicker.hasStartNode') : $t('flow.nodePicker.whenShouldFlowStart') }}
        </div>
        <v-list draggable="false" class="overflow-y-auto">
          <template v-for="(node, index) in triggerNodes">
            <PaywallMenu
              :key="node.typeOverride || node.eventType || node.type"
              :feature="$apptive.constants.features.PRO_NODES"
              :count="isProNode(node) ? 0 : -1"
            >
              <v-list-item
                :data-testid="node.eventType || node.typeOverride || node.type"
                :key="node.name"
                @click="addNode(node)"
                v-on:dragstart="dragStart(node, $event, index)"
                draggable="true"
                :disabled="hasStartNode && node.type === 'start'"
              >
                <FlowNodeIcon
                  class="align-center mr-3"
                  :ref="`nodeIcon${index}`"
                  size="40"
                  :nodeType="node"
                  :opacity="hasStartNode && node.type === 'start' ? 0.5 : 1"
                />
                <v-list-item-content>
                  <v-list-item-title class="bold d-flex align-center">
                    {{ node.typeName }}
                    <v-chip
                      v-if="isProNode(node)"
                      x-small
                      color="primary"
                      class="ml-2"
                    >Pro</v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ node.description }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </PaywallMenu>
          </template>
        </v-list>
      </v-tab-item>

      <v-tab-item class="tab-content">
        <v-list draggable="false" class="overflow-y-auto">
          <template v-for="(node, index) in actionNodes">
            <PaywallMenu
              :key="node.typeOverride || node.eventType || node.type"
              :feature="$apptive.constants.features.PRO_NODES"
              :count="isProNode(node) ? 0 : -1"
            >
              <v-list-item
                :data-testid="node.eventType || node.typeOverride || node.type"
                :key="node.typeName"
                @click="addNode(node)"
                v-on:dragstart="dragStart(node, $event, index)"
                draggable="true"
              >
                <FlowNodeIcon
                  class="align-center mr-3"
                  :ref="`nodeIcon${index}`"
                  size="40"
                  :nodeType="node"
                />
                <v-list-item-content>
                  <v-list-item-title class="bold d-flex align-center">
                    {{ node.typeName }}
                    <v-chip
                      v-if="isProNode(node)"
                      x-small
                      color="primary"
                      class="ml-2"
                    >Pro</v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ node.description }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </PaywallMenu>
          
          </template>
          <v-list-item>
            <div class="mt-3 mb-3 caption text-center flex-grow-0">
      <span class="mr-2">{{$t("flow.nodePicker.featureRequest")}}</span>
      <a href="https://app.apptivegrid.de/api/r/62398e23b8b578b35b971df0/63e630d4f00ac0fe7fb0aab2" target="_blank">{{$t("flow.nodePicker.featureRequestAction")}}</a>
    </div>
          </v-list-item>
        </v-list>
      </v-tab-item>

      <v-tab-item class="tab-content">
        <v-list draggable="false" class="overflow-y-auto">
          <template v-for="(node, index) in helperNodes">
            <PaywallMenu
              :key="node.typeOverride || node.eventType || node.type"
              :feature="$apptive.constants.features.PRO_NODES"
              :count="isProNode(node) ? 0 : -1"
            >
              <v-list-item
                :data-testid="node.eventType || node.typeOverride || node.type"
                :key="node.typeName"
                @click="addNode(node)"
                v-on:dragstart="dragStart(node, $event, index)"
                draggable="true"
              >
                <FlowNodeIcon
                  class="align-center mr-3"
                  :ref="`nodeIcon${index}`"
                  size="40"
                  :nodeType="node"
                />
                <v-list-item-content>
                  <v-list-item-title class="bold d-flex align-center">
                    {{ node.typeName }}
                    <v-chip
                      v-if="isProNode(node)"
                      x-small
                      color="primary"
                      class="ml-2"
                    >Pro</v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ node.description }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </PaywallMenu>
          </template>
        </v-list>
      </v-tab-item>
    
    </v-tabs>
  
  </v-sheet>
</template>

<script>
import { nodeTypes } from '@/constants/nodeTypes.js'
import FlowNodeIcon from '@/components/flow/FlowNodeIcon.vue'
import PaywallMenu from '@/components/paywall/PaywallMenu.vue'

export default {
  props: {
    node: null,
    flowUri: {
      type: String,
      required: true
    }
  },
  inject: {
    plan: {
      default: () => 'start'
    }
  },
  mounted() {
    if (this.hasStartNode) {
      this.activeTab = 1
    }
  },
  watch: {
    hasStartNode(newVal) {
      if (newVal) {
        this.activeTab = 1
      }
    }
  },
  data() {
    return {
      activeTab: 0
    }
  },
  computed: {
    isPro() {
      return this.plan?.() === 'pro'
    },
    flow() {
      return this.$store.getters.flowWithUri(this.flowUri)
    },
    alphaMode() {
      return this.$store.getters.alphaMode
    },
    hasStartNode() {
      return this.flow?.hasStartNode ?? false
    },
    triggerNodes() {
      const nodes = [
        nodeTypes.entityAddedEvent,
        nodeTypes.entityUpdatedEvent,
        nodeTypes.addEntityFormSubmitted,
        nodeTypes.editEntityFormSubmitted,
        nodeTypes.requestTrigger,
        nodeTypes.flowFormPageTrigger,
      ]
      return this.filterAlphaNodes(nodes)
    },
    actionNodes() {
      const nodes = [
        nodeTypes.sendEmail,
        nodeTypes.updateEntity,
        nodeTypes.addEntity,
        nodeTypes.listViewEntities,
        nodeTypes.editLink,
        nodeTypes.flowInstanceLink,
        nodeTypes.flowForm,
        nodeTypes.sendHttpRequest,
        nodeTypes.openAICompletion,
        nodeTypes.datevLodas,
        nodeTypes.slack,
        nodeTypes.teamsMessageWebHook,
        nodeTypes.firebasePush,
        nodeTypes.qrCode,
        nodeTypes.calendarEvent,
        nodeTypes.hubspotAddContact,
        nodeTypes.pdf2text,
        nodeTypes.magentaVerify,
        nodeTypes.magentaVerifyCheck,
        nodeTypes.magentaSMS,
        nodeTypes.speechToText,
      ]
      return this.filterAlphaNodes(nodes)
    },
    helperNodes() {
      const nodes = [
        nodeTypes.exclusiveGateway,
        nodeTypes.parallel,
      ]
      return this.filterAlphaNodes(nodes)
    }
  },
  methods: {
    filterAlphaNodes(nodes) {
      const alphaNodes = [

      ]
      if (!this.alphaMode) {
        return nodes.filter(node => !alphaNodes.includes(node))
      }
      return nodes
    },
    isProNode(node) {
      return node.proNode === true
    },
    dragStart(node, event, nodeIndex) {
      if (this.isProNode(node) && !this.isPro) {
        event.preventDefault()
        return
      }
      event.stopPropagation()
      const originalIcon = this.$refs[`nodeIcon${nodeIndex}`][0].$el
      
      // Create clone of the icon for drag image
      const dragIcon = originalIcon.cloneNode(true)
      dragIcon.style.backgroundColor = 'transparent'
      dragIcon.style.position = 'absolute'
      dragIcon.style.top = '-1000px'
      
      // Ensure icon stays white
      const iconElement = dragIcon.querySelector('.v-icon')
      if (iconElement) {
        iconElement.style.color = 'white'
      }
      
      document.body.appendChild(dragIcon)
      
      event.dataTransfer.setDragImage(dragIcon, 0, 0)
      event.dataTransfer.setData('nodeType', node.type)
      event.dataTransfer.setData('nodeName', node.description)
      if (node.eventType != null) {
        event.dataTransfer.setData('eventType', node.eventType)
      }
      if (node.typeOverride != null) {
        event.dataTransfer.setData('typeOverride', node.typeOverride)
      }
      
      // Remove the clone after drag starts
      requestAnimationFrame(() => {
        document.body.removeChild(dragIcon)
      })
    },
    addNode(node) {
      if (this.isProNode(node) && !this.isPro) {
        return
      }
      this.$emit('addNode', node)
    },
    close() {
      this.$emit('close')
    }
  },
  components: {
    FlowNodeIcon,
    PaywallMenu
  }
}
</script>

<style lang="css" scoped>
.inputContainer {
  background-color: white;
  border: 2px solid #f2f2f2;
  border-radius: 10px;
}
.nodeIcon {
  width: 50px;
  height: 50px;
  border-radius: 100px;
}
.parent-height {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.tab-content {
  height: calc(100vh - 190px);
  overflow: hidden;
}
.v-list {
  height: 100%;
  overflow-y: auto;
}
</style>

